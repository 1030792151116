import { useEffect, useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, CircularProgress, Button, Switch, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LeadsIcon from '../assets/svgs/leadsIcon';
import { KanbanView } from '../sections/kanban/view';
import { CheckCircle, Error } from '@mui/icons-material';
import { SnackbarProvider } from 'src/components/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import { checkButtonAccess } from 'src/utils/common';
import TableFilters from 'src/sections/kyc/tableFilter';
import ChipsProducts from "../sections/kanban/kanban-chip";
import { initialProducts } from "../utils/productList";
import BarChartIcon from '@mui/icons-material/BarChart';
import { button } from 'src/theme/overrides/components/button';
import LeadUsers from './LeadUsers';
// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 20px 88px",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ConnectionIndicator = ({ isConnected }) => {
    const text = "You are disconnected! Try again";

    return (
        <Box style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
            {isConnected === 'pending' && (
                <div style={{ marginRight: 8 }}>
                    <CircularProgress size={18} sx={{ color: 'green' }} />
                </div>
            )}
            {isConnected === 'connected' && (
                <CheckCircle sx={{ color: 'green', fontSize: 18 }} />
            )}
            {isConnected === 'disconnected' && (
                confirm(text) == true ? window.location.reload() : window.location.reload()
                // <Error sx={{ color: 'red', fontSize: 18 }} />
            )}

            <Box component="span" sx={{ fontWeight: 'bold' }}>
                {isConnected === 'pending' && 'Pending'}
                {isConnected === 'connected' && 'Connected'}
                {isConnected === 'disconnected' && 'Disconnected'}
            </Box>
        </Box>
    );
};


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#1890ff',
                ...theme.applyStyles('dark', {
                    backgroundColor: '#177ddc',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255,255,255,.35)',
        }),
    },
}));


const analysisDetails = async (navigate) => {
    navigate("/leads_analysis")
}

const Leads = () => {
    const [connect, setConnect] = useState("pending");
    const navigate = useNavigate();
    const [board, setBoard] = useState();
    const [ticketType, setTicketType] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [leadTypeSwitch, setLeadTypeSwitch] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [load, setLoad] = useState(false);
    const [products, setProducts] = useState(["Console", "DeepCall", "Sarv.com"])
    const location = useLocation();

    const [filter, setFilter] = useState('allTime');
    const handleChangeSelect = (event) => {
        setFilter(event.target.value);
    };
    // const handleChange = (e) => {
    //     setBoard({})
    //     e.target.checked ? setTicketType("support") : setTicketType("lead")
    // }

    useEffect(() => {
        console.log(checkButtonAccess("leads", "r"), checkButtonAccess("support", "r"))
        if (checkButtonAccess("leads", "r") && checkButtonAccess("support", "r")) {
            setTicketType("lead")
        }
        else if (checkButtonAccess("leads", "r")) {
            setTicketType("lead")
            setLeadTypeSwitch(false)
        }
        else {
            setTicketType("support")
            setLeadTypeSwitch(false)
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
        setBoard({});
        const { ticketType } = location.state;
        setTicketType(ticketType);
    }, [location.state.ticketType])

    if (isLoading) {
        return <></>
    }

    const handleChangeSearch = (value) => setSearchValue(value)

    const handleProductUpdate = (updatedProduct) => {
        setProducts(updatedProduct.map((item) => item.label))
    };

    const tableFilter = <TableFilters search={searchValue} handleChange={handleChangeSearch} setLoad={setLoad} />
    const productChip = <ChipsProducts initialChips={initialProducts} onChipUpdate={handleProductUpdate} />

    const dateFilter = (
        <FormControl size='small' sx={{ width: '200px' }}>
            <InputLabel id="demo-simple-select-label">Select filter</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label="Select filter"
                onChange={handleChangeSelect}
            >
                <MenuItem value={"today"}>Today</MenuItem>
                <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                <MenuItem value={"thisWeek"}>This Week</MenuItem>
                <MenuItem value={"lastWeek"}>Last Week</MenuItem>
                <MenuItem value={"thisMonth"}>This Month</MenuItem>
                <MenuItem value={"lastMonth"}>Last Month</MenuItem>
                <MenuItem value={"last30Days"}>Last 30 Days</MenuItem>
                <MenuItem value={"last180Days"}>Last 180 Days</MenuItem>
                <MenuItem value={"last365Days"}>Last 365 Days</MenuItem>
                <MenuItem value={"allTime"}>All Time</MenuItem>
            </Select>
        </FormControl>
    )

    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.leads}`} </title>
            </Helmet>
            <SnackbarProvider>
                <MainSection>
                    {/* {leadTypeSwitch && (<Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography>Leads</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} checked={ticketType === "support" ? true : false} onChange={handleChange} />
                        </Box>
                        <Typography>Support</Typography>
                    </Stack>)} */}
                    {/* Heading and filters */}
                    <HeadingSection>
                        <HeadingContentSection>
                            <Box sx={{
                                width: '16px',
                                height: '16px',
                                mt: '-10px',
                            }}>
                                <LeadsIcon />
                            </Box>
                            <Typography variant="h5" sx={{ color: 'grey.10', fontWeight: 600, marginLeft: "13px" }}>
                                {ticketType === "support" ? "Support" : "Leads"}
                            </Typography>
                        </HeadingContentSection>

                        {ticketType === "lead" && <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box>{productChip}</Box>
                            {dateFilter}
                            <Box sx={{ marginRight: '10px' }}>{tableFilter}</Box>
                            {ticketType === "lead" && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => analysisDetails(navigate)}
                                    size='small'
                                ><BarChartIcon sx={{ margin: "6px" }} />
                                    Leads Analysis</Button>)}
                                    <LeadUsers leads={ticketType}/>
                            <ConnectionIndicator isConnected={connect} />
                        </Box>}    
                         {ticketType ==="support" && <Box sx={{ marginRight: '130px' }}><LeadUsers leads={"support"}  /></Box>}
                    </HeadingSection>

                    {/* <CardsSection /> */}

                    <KanbanView setState={setConnect} ticketType={ticketType} board={board} setBoard={setBoard} searchValue={searchValue} products={products} filter={filter} />

                </MainSection>
            </SnackbarProvider>
        </>
    )
}
export default Leads;