import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomModal from '../../components/modal/customModal';
import WhatsappSettings from './setting';
import WhatsappPlans from './plans';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const WhatsappActions = ({ currentActionProduct, setCurrentActionProduct, user }) => {
    console.log(currentActionProduct, "currentActionProductasasssss")
    const [value, setValue] = React.useState(0);
    const { voiceInfo: { userId } } = user;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const [user, setUser] = useState(null);

    // const fetchVoiceUserId = async () => {
    //     try {
    //         const consoleToken = getItem("token")

    //         // const { vb } = jwtDecode(consoleToken);

    //         // if (!vb) {

    //         const st = {
    //             "session-token": getItem("sessionToken")
    //         }
    //         const payload = {
    //             key: "voice",
    //             token: jwtDecode(consoleToken),
    //         }
    //         const { token } = await axiosPostCall("sessionSharing", payload, false, st);

    //         setItem("token", token);

    //         // }


    //         const user = await axiosVBGetCall("getUserIdAndEmail", { id: email })

    //         setUser(user)
    //     } catch (error) {
    //         console.error("page plan.jsx,func->fetchVoiceUserId, API->", error);
    //         renderErrorMessage(error?.message);
    //         setCurrentActionProduct("")

    //     }
    // }

    useEffect(() => {
        // fetchVoiceUserId();
        //eslint-disable-next-line
    }, [])

    const description = (
        <Box sx={{ width: '100%', mt: '10px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Plans" {...a11yProps(0)} />
                    <Tab label="Aditional Settings" {...a11yProps(1)} />

                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <WhatsappPlans user={user} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <WhatsappSettings user={user} />
            </CustomTabPanel>
        </Box>
    )
    return (
        true && <CustomModal
            open={currentActionProduct === "WaApi"}
            title={"Whatsapp Actions"}
            description={description}
            close={() => setCurrentActionProduct("")}
            // action={forceActions()}
            isCloseIcon={true}
        />
    )
}


export default WhatsappActions;

