import React from "react";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 80,
      width: 250,
    },
  },
};

export default function ChipsProducts({ initialChips, onChipUpdate }) {

  const initialSelectedKeys = initialChips.slice(0, 3).map(chip => chip.key);

  const [selectedKeys, setSelectedKeys] = React.useState(initialSelectedKeys);

  const selectedChips = initialChips.filter(chip => selectedKeys.includes(chip.key));

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedKeys(value);

    onChipUpdate && onChipUpdate(initialChips.filter(chip => value.includes(chip.key)));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "flex-start", flexWrap: "wrap" }}>
      <FormControl sx={{ m: 2, minWidth: 250, maxWidth: "100%" }}>
        <InputLabel id="multiple-chip-select-label">Select Products</InputLabel>
        <Select
          labelId="multiple-chip-select-label"
          multiple
          value={selectedKeys}
          onChange={handleChange}
          input={<OutlinedInput label="Select Products" />}
          renderValue={() => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedChips.map((chip) => (
                <Chip key={chip.key} label={chip.label || "No product selected"} color={chip.color} size="small" variant="outlined" />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          size="small"
          label="All product selected"
        >
          <MenuItem value="" disabled>
            Select product
          </MenuItem>
          {initialChips.map((chip) => (
            <MenuItem key={chip.key} value={chip.key}>
              <Checkbox checked={selectedKeys.includes(chip.key)} />
              <ListItemText primary={chip.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}