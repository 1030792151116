import React, { useState } from 'react';
import { Typography, Box, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import { axiosPostCall } from 'src/services/apisCall';

const BankForm = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            bankName: "",
            accountNumber: "",
            beneficiaryName: "",
            branch: "",
            address: "",
            contactNumber: "",
            ifscCode: "",
            email: ""
        },
        validationSchema: Yup.object({
            bankName: Yup.string().required("Bank Name is required"),
            accountNumber: Yup.string()
                .matches(/^\d+$/, "Account Number must be numeric")
                .required("Account Number is required"),
            beneficiaryName: Yup.string().required("Beneficiary Name is required"),
            branch: Yup.string().required("Branch Name is required"),
            address: Yup.string().required("Address is required"),
            contactNumber: Yup.string()
                .matches(/^\d{10}$/, "Contact Number must be 10 digits")
                .required("Contact Number is required"),
            ifscCode: Yup.string()
                .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code")
                .required("IFSC Code is required"),
            email: Yup.string().email("Invalid email").required("Email is required")
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const bankDetail = {
                    formData: {
                        accountNumber: values.accountNumber,
                        bankName: values.bankName,
                        beneficiaryName: values.beneficiaryName,
                        branchAddress: values.branch,
                        phone: values.contactNumber,
                        ifscCode: values.ifscCode,
                        email: values.email,
                        address: values.address
                    }
                }
                const result = await axiosPostCall("addBankDetails", bankDetail);
                if (result.message === "Success") {
                    resetForm();
                    renderSuccessMessage(result.message);
                    setOpen(false);
                } else {
                    renderErrorMessage(result.message);
                }
            } catch (error) {
                console.error(error);
                renderErrorMessage(error.message);
            }
            setLoading(false);
        }
    });

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>
                <Typography variant="h5" component="span" sx={{ fontWeight: "bold", marginRight: "5px" }}>+</Typography>
                Add Bank Details
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Bank Details Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
                            <TextField
                                label="Bank Name *"
                                name="bankName"
                                value={formik.values.bankName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                                helperText={formik.touched.bankName && formik.errors.bankName}
                            />

                            <TextField
                                label="Account Number *"
                                name="accountNumber"
                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                                helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                            />

                            <TextField
                                label="Beneficiary Name *"
                                name="beneficiaryName"
                                value={formik.values.beneficiaryName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.beneficiaryName && Boolean(formik.errors.beneficiaryName)}
                                helperText={formik.touched.beneficiaryName && formik.errors.beneficiaryName}
                            />

                            <TextField
                                label="Branch Name *"
                                name="branch"
                                value={formik.values.branch}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.branch && Boolean(formik.errors.branch)}
                                helperText={formik.touched.branch && formik.errors.branch}
                            />
                            <TextField
                                label="IFSC Code *"
                                name="ifscCode"
                                value={formik.values.ifscCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.ifscCode && Boolean(formik.errors.ifscCode)}
                                helperText={formik.touched.ifscCode && formik.errors.ifscCode}
                            />
                            <TextField
                                label="Address *"
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />

                            <TextField
                                label="Contact Number *"
                                name="contactNumber"
                                value={formik.values.contactNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                            />

                            <TextField
                                label="Email *"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Box>

                        <DialogActions>
                            <Button onClick={() => setOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                {loading ? <><CircularProgress size={24} color='inherit' />  Uploading...</> : "Submit"}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>            
        </>
    )
};
export default BankForm;