import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

//Common functions
import LogoSvg from '../../assets/svgs/logo';
import { removeBulkItems } from '../../lib/cookies';
import { handleLogout } from '../../services/apisCall';
import { useAuth } from '../../hooks/useAuth';
import Logger from '../../lib/Logger';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSocket } from 'src/context/SocketContext';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const logger = new Logger("navBar")

//@material
import { AppBar, Toolbar, Button, Box, Avatar, Menu, MenuItem, Divider, Badge, Typography, IconButton, ListItemIcon, ListItemText } from "@mui/material";

//material icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { checkButtonAccess, checkRouteAccess } from '../../utils/common';
import AdminsSvg from 'src/assets/svgs/admins';
import KycSvg from 'src/assets/svgs/kyc';
import LeadsSvg from 'src/assets/svgs/leads';
import Iconify from 'src/components/iconify';
import BillIcon from 'src/assets/svgs/bill';
import { TokenContext } from "../../context/TokenContext";

export default function Navbar() {
    const socket = useSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState();
    const [profileUrl, setProfileUrl] = useState(null);
    const openProfile = Boolean(anchorEl);
    const valid = useAuth();
    const { decodedToken } = useContext(TokenContext);

    const [anchorElNotification, setAnchorElNotification] = useState(null);
    const openNotification = Boolean(anchorElNotification);
    const [incompleteKYCData, setIncompleteKYCData] = useState([]);

    const [leadsAnchorEl, setLeadsAnchorEl] = useState(null);
    const leadsOpen = Boolean(leadsAnchorEl);

    const handleClick = (event) => {
        setLeadsAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setLeadsAnchorEl(null);
    };

    const logout = async () => {

        try {
            await handleLogout();
            removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }]);
            if (socket) {
                socket.disconnect();
            }
            return window.location.href = "/";
        } catch (error) {
            logger.error("navbar [logout] catchError", error);
        }
    }

    const navbar = [
        {
            title: "Billing",
            active: (location?.pathname === "/billing" || location?.pathname === "/create_quotation" || location?.pathname === "/quotation_analysis"),
            path: "/billing",
            startIcon: <BillIcon />
        },
        /* {
            title: "Leads",
            active: location?.pathname === "/leads",
            path: "/leads",
            startIcon: <LeadsSvg />
        }, */
        {
            title: "KYC",
            active: location?.pathname === "/kyc",
            path: "/kyc",
            startIcon: <KycSvg />
        },
        {
            title: "Admins",
            active: location?.pathname === "/users",
            path: "/users",
            startIcon: <AdminsSvg />
        },
        {
            title: "Plans",
            active: location?.pathname === "/plans",
            path: "/plans",
            startIcon: <Iconify icon="stash:plan" />
        },
        {
            title: "Developer Option",
            active: location?.pathname === "/updatepermissions",
            path: "/updatepermissions",
            startIcon: <DeveloperModeIcon />
        }
    ]

    useEffect(() => {
        try {
            if (valid) {
                if (decodedToken) {
                    try {
                        const { userName = "", profileUrl = "" } = decodedToken;
                        setName(userName)
                        setProfileUrl(profileUrl)
                    } catch (error) {
                        console.error("Failed to decode token:", error);
                    }
                } else {
                    console.error("Token is not available or invalid in navbar index.");
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [valid])

    useEffect(() => {

        if (socket && decodedToken) {
            const { profileUrl = "", userName = "", email = "" } = decodedToken;
            const data = { avatarUrl: profileUrl, name: userName, id: email };
            handleEmit(data, "kyc_incomplete", (err, response) => {
                if (err) {
                    console.log("error in socket connection:", err)
                } else {
                    setIncompleteKYCData(response)
                }
            })

        }
    }, [socket, decodedToken])

    const handleEmit = (data, type, func) => {
        if (!socket) {
            func(true, "Please connect to server.")
            return
        }

        switch (type) {
            case "kyc_incomplete":
                socket.emit("kyc_incomplete", data, func);
                break;
            default:
                func(true);
        }
    }

    return (
        <Box sx={{
            // pl: '68px',
            flexDirection: 'flex-end',
            position: 'fixed',
            width: '100%',
            color: '#fff',
            zIndex: 2,
            boxShadow: '68px 0.2px 3px 0px rgba(145, 158, 171, 0.2)',
        }}>
            <AppBar position="static" component="nav" sx={{
                backgroundColor: '#fff',
                boxShadow: 'none'
            }}>
                <Toolbar>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '12px',
                        ml: -1.3
                    }}>
                        <LogoSvg />
                        {/* <Typography sx={{
                            fontSize: '24px',
                            fontWeight: 700,
                            // color: 'grey.800'
                            color: 'primary.main'
                        }}>
                            Sarv.com
                        </Typography> */}
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                        // gap: '20px',
                        // backgroundColor: '#EDEFF2'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            // width: '100%',
                            gap: '10px',
                            backgroundColor: '#EDEFF2',
                            padding: '6px 7px',
                            borderRadius: '30px',
                            alignItems: 'center'
                        }}>

                            {checkRouteAccess("/leads") && <Button
                                key={"lead"}
                                size='medium'
                                sx={{
                                    color: location?.pathname === "/leads" ? "grey.800" : "grey.700",
                                    bgcolor: location?.pathname === "/leads" ? "background.default" : "transparent",
                                    padding: '5px 14px',
                                    borderRadius: '20px',
                                    "&:hover": {
                                        color: location?.pathname === "/leads" ? "grey.800" : "grey.700",
                                        bgcolor: location?.pathname === "/leads" ? "background.default" : "transparent",
                                    }
                                }}
                                onClick={handleClick}
                                startIcon={<LeadsSvg />}
                            >
                                Leads
                            </Button>}
                            <Menu
                                id="basic-menu"
                                anchorEl={leadsAnchorEl}
                                open={leadsOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                PaperProps={{
                                    sx: {
                                        width: "120px",
                                    },
                                }}
                            >
                                {checkButtonAccess("leads", "r") && <MenuItem onClick={() => setLeadsAnchorEl(null) || navigate("/leads", { state: { ticketType: "lead" } })}>
                                    <ListItemIcon sx={{ minWidth: "36px", mr: "5px" }}>
                                        <Iconify icon="mdi:leads" />
                                    </ListItemIcon>
                                    <ListItemText>Lead</ListItemText>
                                </MenuItem>}
                                {checkButtonAccess("support", "r") && <MenuItem onClick={() => setLeadsAnchorEl(null) || navigate("/leads", { state: { ticketType: "support" } })}>
                                    <ListItemIcon sx={{ minWidth: "36px", mr: "5px" }}>
                                        <Iconify icon="flat-color-icons:online-support" />
                                    </ListItemIcon>
                                    <ListItemText>Support</ListItemText>

                                </MenuItem>}
                            </Menu>
                            {navbar.filter(({ path }) => checkRouteAccess(path)).map(({ title, active, path, icon, startIcon }) => {
                                return (
                                    <Button onClick={() => navigate(path)}
                                        key={title}
                                        size='medium'
                                        sx={{
                                            color: active ? "grey.800" : "grey.700",
                                            bgcolor: active ? "background.default" : "transparent",
                                            // height: '34px',
                                            padding: '5px 14px',
                                            borderRadius: '20px',
                                            "&:hover": {
                                                color: active ? "grey.800" : "grey.700",
                                                bgcolor: active ? "background.default" : "transparent",
                                            }
                                        }}
                                        startIcon={startIcon}
                                        endIcon={icon}>
                                        {title}
                                    </Button>
                                )
                            })}

                            <Box sx={{ mr: '14px' }} onClick={(e) => setAnchorElNotification(e.currentTarget)}>
                                <Badge color="success" variant="dot" >
                                    <Iconify icon="clarity:notification-line" style={{ color: '#000', cursor: 'pointer' }} />
                                </Badge>
                            </Box>


                            {valid && <Box sx={{ mr: '3px' }}>
                                <Avatar variant="square"
                                    src={profileUrl ? profileUrl : "../../images/avatar.png"}
                                    sx={{
                                        borderRadius: '50%',
                                        alignItems: 'center',
                                        bgcolor: "primary.main",
                                        cursor: "pointer",
                                        height: '30px',
                                        width: '30px',
                                    }}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                    {name?.slice(1)[0]}
                                </Avatar>
                            </Box>}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Profile menu */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openProfile}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            width: 175,
                            height: 'auto',
                            borderRadius: 2,
                            '& .MuiMenuItem-root': {
                                px: 2,
                                py: 1.5,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem
                        sx={{
                            display: 'flex',
                            gap: 1,
                            fontWeight: 400,
                            color: 'grey.700',
                            '&:hover': {
                                color: 'grey.800',
                                backgroundColor: 'grey.300',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => navigate('/profile')}
                    >
                        <AccountBoxIcon />
                        Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={{
                            display: 'flex',
                            gap: 1,
                            fontWeight: 400,
                            color: 'grey.700',
                            '&:hover': {
                                color: 'grey.800',
                                backgroundColor: 'grey.300',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={logout}
                    >
                        <LogoutIcon sx={{ color: 'red' }} />
                        Logout
                    </MenuItem>
                </Menu>
            </Box>

            <Menu
                anchorEl={anchorElNotification}
                id="notification-menu"
                open={openNotification}
                onClose={() => setAnchorElNotification(null)}
                onClick={() => setAnchorElNotification(null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        maxWidth: 475,
                        height: 'auto',
                        borderRadius: 2,
                        '& .MuiMenuItem-root': {
                            px: 2,
                            py: 1.5,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {incompleteKYCData.length > 0 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', padding: '6px 10px' }}>
                            <Typography sx={{ fontWeight: 500 }} >
                                <Typography component={'span'} sx={{ mr: 1, }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        style={{ marginBottom: '-28px' }}
                                    >
                                        <g fill="none">
                                            <path fill="url(#fluentColorWarning200)" d="M8.681 2.785c.568-1.047 2.071-1.047 2.638 0l6.5 12.002A1.5 1.5 0 0 1 16.502 17H3.498a1.5 1.5 0 0 1-1.319-2.215z"></path>
                                            <path fill="url(#fluentColorWarning201)" d="M10.5 7.5a.5.5 0 0 0-1 0v4a.5.5 0 0 0 1 0z"></path>
                                            <path fill="url(#fluentColorWarning202)" d="M10 14.5a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5"></path>
                                            <defs><linearGradient id="fluentColorWarning200" x1={4.497} x2={13.972} y1={-0.344} y2={18.914} gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#ffcd0f"></stop><stop offset={1} stopColor="#fe8401"></stop>
                                            </linearGradient><linearGradient id="fluentColorWarning201" x1={8} x2={11.603} y1={7} y2={14.686} gradientUnits="userSpaceOnUse"><stop stopColor="#4a4a4a">
                                            </stop><stop offset={1} stopColor="#212121">
                                                    </stop></linearGradient><linearGradient id="fluentColorWarning202" x1={8} x2={11.603} y1={7} y2={14.686} gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#4a4a4a"></stop><stop offset={1} stopColor="#242424"></stop></linearGradient></defs>
                                        </g>
                                    </svg>
                                </Typography>
                                <Typography sx={{ fontWeight: 500, ml: "25px" }}>
                                    Action Required:{" "}
                                    <Typography
                                        component="span"
                                        onClick={() => navigate('/kycNotification', { state: incompleteKYCData })}
                                        sx={{ fontWeight: 400, cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                    >
                                        Agents with pending KYC verification need approval. Complete the process to avoid disruptions.
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Box>

                        <Divider />
                    </>
                )}


                <Box sx={{ padding: '6px 10px' }} onClick={() => navigate('/release')}>
                    <Typography sx={{ fontWeight: 500, textAlign: 'start', cursor: "pointer" }}>
                        🔔 {" "} New Release v1.2.0 is now live! 🚀
                    </Typography>
                </Box>

            </Menu>

        </Box >
    )
}

