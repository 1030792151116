import { useState } from 'react';
import { Box , TextField, FormControl, InputLabel, Select, MenuItem,FormHelperText} from '@mui/material';
import CustomModal from 'src/components/modal/customModal';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled, useTheme } from '@mui/material/styles';
import { allowedCountries , ROUTE, PLAN_TYPE} from 'src/lib/Enums';
import { checkButtonAccess } from 'src/utils/common';
import { axiosPostCall } from 'src/services/apisCall';
const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));


const WhatsappPlansModal = ({ openModal, forceLoad, loadit,  handleCloseModal , title,edit,data}) => {
    const theme = useTheme(); 
const inputSx = {
    color: theme.palette.text.primary,
    // borderColor: 'grey.700',
    borderRadius: "6px",
};

    const [country, setCountry] = useState(edit ? data?.country : '');
    const [route, setRoute] = useState(edit ? data?.route : '');
    const [type, setType] = useState(edit ? data?.type : '');
    const [error, setError] = useState({});

    const handleChange = async (e) => {
        e.preventDefault();
        const errorTemp = {};
        if (!country) {
            errorTemp.country = "Country is required";
        }
        if (!route) {
            errorTemp.route = "Route is required";
        }
        if (!type) {
            errorTemp.type = "Type is required";
        }
        setError(errorTemp);
        if (Object.keys(errorTemp).length === 0) {
            try {
                const { message } = await axiosPostCall("addWhatsappPlan", { countryCode:country, route, planType:type });
                renderSuccessMessage(message);
                handleCloseModal();
                forceLoad(!loadit)
            } catch (error) {
               
                renderErrorMessage(error?.message);
            }
        }
    };
    const description = (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '25px',
                width: '100%',
                padding: '25px'
            }}
            noValidate
            autoComplete="off"
        >
            <RowTow>
                <FormControl fullWidth error={error.country}>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={country}
                        label="Country*"
                        onChange={(e)=>setCountry(e.target.value)}
                        name="Country"
                    >
                        {Object.entries(allowedCountries).map(([code, country]) => (
                            <MenuItem key={code} value={code}>{country}</MenuItem>
                        ))}
                    
                    </Select>
                    {error.country && <FormHelperText>{error.country}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth error={error.route}>
                    <InputLabel id="demo-simple-select-label">Route</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={route}
                        label="route*"
                        onChange={(e)=>setRoute(e.target.value)}
                        name="route"
                    >
                        {Object.entries(ROUTE).map(([code, Route]) => (
                            <MenuItem key={code} value={code}>{Route}</MenuItem>
                        ))}
                    
                    </Select>
                    {error.route && <FormHelperText>{error.route}</FormHelperText>}
                </FormControl>
            </RowTow>
            <RowTow>
                <FormControl fullWidth error={error.type}>
                    <InputLabel id="demo-simple-select-label">PlanType</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Country*"
                        onChange={(e)=>setType(e.target.value)}
                        name="type"
                    >
                        {Object.entries(PLAN_TYPE).map(([code, planType]) => (
                            <MenuItem key={code} value={code}>{planType}</MenuItem>
                        ))}
                    
                    </Select>
                    {error.type && <FormHelperText>{error.type}</FormHelperText>}
                </FormControl>
                
            </RowTow>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                pt: '10px',
                pb: '20px'
            }}>
                <LoadingButton
                    variant='outlined'
                    onClick={(e) => handleChange(e)}
                    disabled={!checkButtonAccess("addUpdateWhatsappPlan", "w")}
                >
                    {edit ? "Update" : "Create"}
                </LoadingButton>
            </Box>
        </Box>
    );

    return openModal && (
        <CustomModal
            open={openModal}
            title= {title}
            description={description}
            close={handleCloseModal}
            isCloseIcon={true}
        />
    );
};
export default WhatsappPlansModal;