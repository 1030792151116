import { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { getSupportColumns } from "src/utils/common";

export default function ColumnSettingsModal({ open, onClose,  /*columns,*/ onSave, data, update }) {
    const [nextColumnId, setNextColumnId] = useState("");
    const [columnName, setColumnName] = useState("");
    const [columnType, setColumnType] = useState("");
    const [time, setTime] = useState();
    const [templateId, setTemplateId] = useState();
    const [columns, setColumns] = useState([]);

    const fetchColumn = async () => {
        const column = await getSupportColumns();
        setColumns(column);

    }

    useEffect(() => {
        fetchColumn();

    }, [])

    useEffect(() => {
        if (update) {
            console.log("column data", data)
            const { columnType, name, slaRule: { nextColumnId, time, templateId } = {} } = data;
            setColumnName(name);
            setColumnType(columnType);
            setNextColumnId(nextColumnId);
            setTime(time);
            setTemplateId(templateId);
        }
    }, [])



    const handleSave = () => {
        onSave({ nextColumnId, columnName, columnType, time, templateId });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Column Settings</DialogTitle>
            <DialogContent>
                {/* Column Name */}
                <TextField
                    fullWidth
                    margin="dense"
                    label="Column Name"
                    value={columnName}
                    onChange={(e) => setColumnName(e.target.value)}
                />

                {/* Column Type */}
                <FormControl fullWidth margin="dense">
                    <InputLabel>Column Type</InputLabel>
                    <Select value={columnType} onChange={(e) => setColumnType(e.target.value)} label="Column Type" >
                        <MenuItem value="solved">Solved</MenuItem>
                        <MenuItem value="on_hold">On Hold</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                    </Select>
                </FormControl>

                {/* Next Column ID (Dropdown) */}
                {/* <FormControl fullWidth margin="dense">
                    <InputLabel>Next Column</InputLabel>
                    <Select value={nextColumnId} onChange={(e) => setNextColumnId(e.target.value)}>
                        {columns.map((col) => (
                            <MenuItem key={col.id} value={col.id}>
                                {`${col.name}-${col.columnType}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                {/* Time Input */}
                {/* <TextField
                    fullWidth
                    margin="dense"
                    label="Time (minutes)"
                    type="number"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                /> */}

                {/* Template ID Input */}
                {/* <TextField
                    fullWidth
                    margin="dense"
                    label="Template ID"
                    type="number"
                    value={templateId}
                    onChange={(e) => setTemplateId(e.target.value)}
                /> */}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
