import React from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';

import pageTitle from "../utils/pageTitles.json";
import { BlankCard } from '../components/cards/blankCard';
import { PlanList } from 'src/sections/whatsapp/plans/planList';
import UsersListSvg from '../assets/svgs/usersList';
import { ToastContainer } from 'react-toastify';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { checkButtonAccess } from 'src/utils/common';
import CustomModal from 'src/components/modal/customModal';
import WhatsappPlansModal from 'src/sections/whatsapp/plans/addUpdatePlans';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: '100vh',
    overflow: 'hidden'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));


const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const DetailsListSection = styled('div')(() => ({
    width: '100%',
    height: 'calc(100vh - 155px)',//'554px',
    overflow: 'hidden',
    overflowY: "scroll",
}));

const PlanListSection = (loadit) => (
    <Box sx={{ width: '100%' }}>
        <DetailsListSection className='reportListScroll'>
            <PlanList loadit={loadit.loadit} />
        </DetailsListSection>
    </Box>
)


const WhatsappPlans = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const [loadit, forceLoad] = React.useState(false);

    const handleClick = () => {
        // if (!checkButtonAccess("addNewUser", "w")) return renderErrorMessage(ErrorMessage["invalidClick"])
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
 

    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.console}`} </title>
            </Helmet>

            <MainSection>

                {/* Heading and filters */}
                <HeadingSection>
                    <HeadingContentSection>
                        <Box sx={{
                            widht: '16px',
                            height: '16px',
                            mt: "-10px",
                        }}>
                            <UsersListSvg />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Whatsapp Plans
                        </Typography>
                    </HeadingContentSection>
                    {checkButtonAccess("createConsoleUser", "r") && <Button
                        startIcon={<PersonAddIcon />}
                        sx={{
                            backgroundColor: 'rgb(33, 43, 54)',
                            color: 'grey.100',
                            p: '0px 20px 0px 20px',
                            '&:hover': {
                                backgroundColor: 'rgb(69, 79, 91)'
                            }
                        }}
                        onClick={() => handleClick()}
                    >
                        Create Plans
                    </Button>}
                </HeadingSection>

                <BlankCard
                    key="1"
                    sx={{
                        height: '100%',
                        width: '100%',
                        padding: '0px',
                        overflow: 'hidden',
                        // borderTopLeftRadius: '0px',
                        // borderTopRightRadius: '0px'
                    }}
                >
                    <PlanListSection loadit={loadit} sx={{ maxHeight: "100%", overflow: 'hidden' }} />
                </BlankCard> 

            </MainSection>
            <WhatsappPlansModal
            openModal={openModal}
            forceLoad={forceLoad}
            loadit={loadit}
            handleCloseModal={handleCloseModal}
            title="Add Whatsapp Plans"
            />
           
        </>
    )
}


export default WhatsappPlans;

