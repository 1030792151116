import { styled } from '@mui/material/styles';
import { Typography, Box, CircularProgress } from '@mui/material';
import AnalysisTable from 'src/sections/billing/billAnalysisTable';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import AssessmentIcon from '@mui/icons-material/Assessment';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: 'calc(100vh - 0px)',
    overflow: 'hidden'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const LeadsAnalysis = () => {
    return (
        <>
        <Helmet>
                <title> {`${pageTitle?.billingAnalysis}`} </title>
            </Helmet>
        <MainSection>
            <HeadingSection>
                <HeadingContentSection>
                    <Box sx={{
                        widht: '16px',
                        height: '16px',
                        mt: "-10px",
                    }}>
                        <AssessmentIcon />
                    </Box>
                    <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                        {/* <AddCircleOutlineIcon /> */}
                        Quotation Analysis
                    </Typography>
                </HeadingContentSection>

            </HeadingSection>

            <AnalysisTable />
        </MainSection>
        </>
    )
}

export default LeadsAnalysis