
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { green } from '@mui/material/colors';
import { renderErrorMessage } from 'src/lib/toastMessage';
import { useState, useEffect } from 'react';
import { useSocket } from "src/context/SocketContext";


function LeadUsers({ leads }) {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const socket = useSocket();

  useEffect(() => {
    if (socket) {

      socket.emit("online_users", { leads}, (err, users) => {
        if (err) {
          renderErrorMessage(err.message);
          return;
        }
        setUsers(users);
      });
    }
  }, [socket]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AvatarGroup
        total={users.length}
        renderSurplus={(surplus) => (
          <span >+{surplus}</span>
        )}
        onClick={handleOpen} style={{ cursor: 'pointer' }}
      >

        {users.slice(0, 4).map((user) => (
          <Tooltip key={user._id} title={user.userName} arrow>
            <Avatar sx={{ height: "24px", width: "24px" }} alt={user.userName} src={user.profileUrl} />
          </Tooltip>
        ))}
      </AvatarGroup>

      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: 500, height: 550 } }}>
        <DialogTitle>Online Users</DialogTitle>
        <DialogContent>
          <List>
            {users.map((user) => (
              <ListItem key={user._id}>
                <ListItemAvatar >
                  <Avatar alt={user.userName} src={user.profileUrl} />
                </ListItemAvatar>
                <ListItemText primary={user.userName} />
                <CircleIcon style={{ color: green[500] }} fontSize="small" />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LeadUsers;





