import React, { useState, useEffect } from 'react';
import commonMessage from '../../utils/commonMessage.json'
import {
    Typography, Table, TableBody, TableCell, TableContainer, TablePagination,
    TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { axiosGetCall } from '../../services/apisCall';
import { renderErrorMessage } from '../../lib/toastMessage';

import Logger from '../../lib/Logger';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
import NetworkError from '../../components/Error/networkError';
const logger = new Logger("Quotation analysis list");

const PagesRowSection = styled('div')(() => ({
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: "#fff",
    borderRadius: '6px',
    overflow: 'hidden'
}));

const HeadingSection = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '15px',
    padding: '20px 15px',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const ActionTitle = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 500,
    color: "#727374",
}));

const Heading = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 500,
    color: "#2C2D2F",
}));

const QuotationAnalysis = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)

    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [filter, setFilter] = React.useState('thisMonth');

    const handleChangeSelect = (event) => {
        setFilter(event.target.value);
        setLoad(true)
    };

    const getConsoleUserList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: 10000, filter }
            if (search) params.email = search
            setNetworkError(false)
            const { result, count } = await axiosGetCall("getQuotationAnalysis", params)
            setRows(result)
            setCount(count)
        } catch (error) {
            logger.error("quotation analysis table", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage)
        setLoad(true)
        setLoading(true)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLoad(true)
        setLoading(true)

    };

    useEffect(() => {
        if (load) {
            setLoading(true)
            getConsoleUserList()
            setLoad(false)
        }
    }, [load])

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };
    return (
        <PagesRowSection>
            {/* <ToastContainer /> */}
            <HeadingSection>
                <Heading>

                </Heading>

                <FormControl size='small' sx={{ width: '200px' }}>
                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        label="Select"
                        onChange={handleChangeSelect}
                    >
                        <MenuItem value={"thisMonth"}>This Month</MenuItem>
                        <MenuItem value={"lastMonth"}>Last Month</MenuItem>
                        <MenuItem value={"last90Days"}>Last 3 Months</MenuItem>
                        <MenuItem value={"last180Days"}>Last 6 Months</MenuItem>
                        <MenuItem value={"last365Days"}>Last 12 Months</MenuItem>
                    </Select>
                </FormControl>

            </HeadingSection>

            <TableContainer component={Paper} sx={{ borderRadius: '0px', maxHeight: 680 }}>
                <Table stickyHeader aria-label="sticky table" size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ minWidth: 150, }}>Name</TableCell>
                            <TableCell align="center" style={{ minWidth: 10, }}>Email</TableCell>
                            <TableCell align="center" style={{ minWidth: 50, }}>Total Quotation</TableCell>
                            <TableCell align="center" style={{ minWidth: 50, }}>Total Payment Done</TableCell>
                            <TableCell align="center" style={{ minWidth: 80, }}>Total Payment</TableCell>
                            <TableCell align="center" style={{ minWidth: 80, }}>Completed Payment </TableCell>
                            <TableCell align="center" style={{ minWidth: 80, }}>Incomplete Payment </TableCell>
                            <TableCell align="center" style={{ minWidth: 50, }}>Total Active</TableCell>
                            <TableCell align="center" style={{ minWidth: 50, }}>Total Expired</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? <TableBody>
                        {rows.map(({ userName, email, origin = "", details }) => (
                            <TableRow
                                key={email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" style={{ minWidth: 100 }}>
                                    {userName}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 150 }}>
                                    {email || "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {details.totalQuotation|| "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {details.payment|| "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {formatCurrency(details.totalPaymentAmount) || "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {formatCurrency(details.completePaymentAmount )|| "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {formatCurrency(details.incompletePaymentAmount) || "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {details.active|| "--"}
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: 30, }}>
                                    {details.expired|| "--"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                        :
                        <TableBody>
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                    <TableCell
                                        key={item}
                                        align={"center"}
                                        sx={{
                                            color: 'grey.400',
                                            borderBottom: '1px solid',
                                            borderColor: 'grey.800'
                                        }}
                                    >
                                        <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                                    </TableCell>
                                ))}
                            </TableRow>

                        </TableBody>
                    }
                </Table>
            </TableContainer>
            {count > 0 && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {!(count || loading) &&
                <Box sx={{
                    width: '100%',
                    height: '48vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <NetworkError message={!networkError ? commonMessage?.noDataFound : commonMessage?.networkError} />
                </Box>

            }

        </PagesRowSection >

    )
}

export default QuotationAnalysis;
