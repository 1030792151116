module.exports = {
    ROUTE: {
        transactional: "Transactional",
        promotional: "Promotional",
      },
    
      PLAN_TYPE: {
        C: "Credit",
        A: "Amount",
      },
    allowedCountries: {
        IN: "India",
        BD: "Bangladesh",
      },
    mainResselerId: "66487782"
}