import { TextField, Box, Typography, Stack, Select, MenuItem, InputLabel, FormControl, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { axiosGetCall, axiosPatchCall } from 'src/services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import Logger from '../../lib/Logger';
import Iconify from '../../components/iconify';
import { set } from 'lodash';

const logger = new Logger("asetting")

const BasicDatePicker = ({ value, onChange, dateError, resetError, label }) => {
    const [_, setOpen] = useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                views={['hours']}
                label={label}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                    textField: {
                        size: 'small',
                        error: Boolean(dateError),
                        helperText: Boolean(dateError) && dateError,
                        onClick: () => resetError()
                    },
                    actionBar: {
                        actions: ['today', 'clear'],
                    },
                }}
                ampm={true}
                value={value}
                onChange={(newValue) => onChange(newValue)}
            />
        </LocalizationProvider>
    )
}

const requestList = [
    { label: "Self", value: "U" },
    { label: "Downline", value: "D" },
    { label: "Hierarchy", value: "H" }
]

const WhatsappSettings = ({ user }) => {
    const inputSx = {
        color: "grey.900",
        borderColor: 'grey.700',
        borderRadius: "6px",
    };

    const [startingRange, setStartingRange] = useState(0);
    const [endingRange, setEndingRange] = useState(0);
    const [startCuttingPercentage, setStartCuttingPercentage] = useState(0);
    const [endCuttingPercentage, setEndCuttingPercentage] = useState(0);
    const [cuttingRequestFor, setCuttingRequestFor] = useState("U");
    const [mockAnswer, setMockAnswer] = useState(0);
    const [load, setLoad] = useState(false);
    const [cuttingLoader, setCuttingLoader] = useState(false);

    const updateCuttingPercentage = async () => {
        try {
            if (startCuttingPercentage > endCuttingPercentage) {
                return renderErrorMessage("Start Cutting Percentage must be less than End Cutting Percentage");
            }

            if (startCuttingPercentage === endCuttingPercentage && startCuttingPercentage !== 0) {
                return renderErrorMessage("Start Cutting Percentage and End Cutting Percentage not be same");
            }

            const payload = {
                userId: user?.voiceInfo?.userId,
                action: "setCutting",
                startingRange: parseInt(startingRange),
                endingRange: parseInt(endingRange),
                startCuttingPercentage: parseInt(startCuttingPercentage),
                endCuttingPercentage: parseInt(endCuttingPercentage),
                requestFor: cuttingRequestFor,
            };
            if (user?.voiceInfo?.userType === "reseller") {
                payload.mockToAnswer = mockAnswer;
            }

            setCuttingLoader(true);
            await axiosPatchCall("updateWhatsappProfileSetting", payload);
            setCuttingLoader(false);
            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error);
            setCuttingLoader(false);
            renderErrorMessage(error.message);
        }
    };

    const handleReset = async (type) => {
        let payload = {
            userId: user?.voiceInfo?.userId,
            action: "resetSetting",
            resetActionType: "",
            requestFor: "U"
        };

        switch (type) {
            case "cutting":
                payload.resetActionType = "setCutting";
                payload.requestFor = cuttingRequestFor;
                break;
            default:
                break;
        }

        try {
            await axiosPatchCall("updateWhatsappProfileSetting", payload);
            setLoad((prev) => !prev);
        } catch (error) {
            logger.error(error);
            renderErrorMessage(error.message);
        }
    };

    const handleSubmit = async (type) => {
        if (type === "cutting") {
            await updateCuttingPercentage();
        }
    };

    const fetchSettingDetails = async () => {
        try {
            const params = { requestUserId: user?.voiceInfo?.userId, requestName: "all" };
            const { data: { startingRange, endingRange, startCuttingPercentage, endCuttingPercentage, mockToAnswer } } = await axiosGetCall("getWhatsappUserSettingsInfo", {
                requestName: "setCutting",
                requestUserId: user?.voiceInfo?.userId,
            });
            setStartCuttingPercentage(startCuttingPercentage);
            setStartingRange(startingRange);
            setEndingRange(endingRange);
            setEndCuttingPercentage(endCuttingPercentage);
            setMockAnswer(mockToAnswer);
        } catch (error) {
            logger.error(error);
        }
    };

    useEffect(() => {
        fetchSettingDetails();
    }, [load]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "10px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    mb: "15px",
                }}
            >
                <Typography>cutting</Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius: "7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <Box>
                    <Box sx={{
                        display: "flex",
                        gap: "6px",
                        mb: "15px",
                    }}>
                        <TextField
                            fullWidth
                            label="Starting Range"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={startingRange}
                            onChange={(e) => setStartingRange(e.target.value)}
                            size="small"
                        />

                        <TextField
                            fullWidth
                            label="Ending Range"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={endingRange}
                            onChange={(e) => setEndingRange(e.target.value)}
                            size="small"
                        />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        gap: "6px",
                        mb: "15px",
                    }}>
                        <TextField
                            fullWidth
                            label="Start Cutting Percentage"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={startCuttingPercentage}
                            onChange={(e) => setStartCuttingPercentage(e.target.value)}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            label="End Cutting Percentage"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={endCuttingPercentage}
                            onChange={(e) => setEndCuttingPercentage(e.target.value)}
                            size="small"
                        />
                    </Box>
                </Box>

                <Stack width={"100%"} direction={"row"} alignItems={"center"}>
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Stack
                            gap={2}
                            direction={"row"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                mb: "10px"
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="request-select">Request For</InputLabel>
                                <Select
                                    labelId="request-select"
                                    id="request-select"
                                    value={cuttingRequestFor || ""}
                                    onChange={(e) => setCuttingRequestFor(e?.target?.value)}
                                    label="Request For"
                                    size="small"
                                    displayEmpty
                                >
                                    {requestList.map(({ label, value }) => (
                                        <MenuItem key={label} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    )}
                </Stack>

                <Stack width={"100%"} direction={"row"} alignItems={"center"}>
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Stack
                            gap={2}
                            direction={"row"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "80%",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="mock-select">Mock to answer</InputLabel>
                                <Select
                                    labelId="mock-select"
                                    id="mock-select"
                                    value={mockAnswer}
                                    onChange={(e) => setMockAnswer(e?.target?.value)}
                                    label="Mock to answer"
                                    size="small"
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"row"} gap={1}>
                            <IconButton onClick={() => handleReset("cutting")}>
                                <Iconify icon="ix:reset" />
                            </IconButton>
                            <LoadingButton
                                loading={cuttingLoader}
                                variant="contained"
                                sx={{ width: "100px" }}
                                onClick={() => handleSubmit("cutting")}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}

export default WhatsappSettings;
