
import { useState } from 'react';

// import { useResponsive } from 'src/hooks/use-responsive';
// import { usePopover } from 'src/components/custom-popover';

export function useEmployee({ ws }) {
    const [list, setList] = useState([])
    const [listLoading, setListLoading] = useState(true)
    const [listNotInYet, setListNotInYet] = useState([])
    const [listNotInYetLoading, setListNotInYetLoading] = useState(true)
    const [listOutOfOffice, setListOutOfOffice] = useState([])
    const [listOutOfOfficeLoading, setListOutOfOfficeLoading] = useState(true)
    const [list4thFloor, setList4thFloor] = useState([])
    const [list4thFloorLoading, setList4thFloorLoading] = useState(true)
    const [list3rdFloor, setList3rdFloor] = useState([])
    const [list3rdFloorLoading, setList3rdFloorLoading] = useState(true)
    const [list2ndFloor, setList2ndFloor] = useState([])
    const [list2ndFloorLoading, setList2ndFloorLoading] = useState(true)
    const [listOtherFloor, setListOtherFloor] = useState([])
    const [listOtherFloorLoading, setListOtherFloorLoading] = useState(true)
    const [completeReport, setCompleteReport] = useState([])
    const [reportLoading, setReportLoading] = useState(true)

    const fetchLogs = async (data) => {
        try {
            setListLoading(true);
            setList([])
            ws.emit("e_list", data, (err, response) => {
                const sortedRes = (response || []).reverse()
                console.log(sortedRes)
                setList(sortedRes || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setListLoading(false);
        }
    }

    const fetchReportLogs = async (data) => {
        try {
            setReportLoading(true);
            setCompleteReport([])
            ws.emit("e_list_report", data, (err, response) => {
                const sortedRes = (response || []).reverse()
                console.log(sortedRes)
                setCompleteReport(sortedRes || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setReportLoading(false);
        }
    }

    const fetchEmpNotInYet = async (data) => {
        try {
            setListNotInYetLoading(true);
            // setListNotInYet([])
            ws.emit("e_list_not_in_yet", data, (action, err, response) => {
                if (err) throw new Error(response)
                setListNotInYet(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setListNotInYetLoading(false);
        }
    }

    const fetchEmpOutOfOffice = async (data) => {
        try {
            setListOutOfOfficeLoading(true);
            // setListOutOfOffice([])
            ws.emit("e_list_out_of_office", data, (action, err, response) => {
                if (err) throw new Error(response)
                setListOutOfOffice(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setListOutOfOfficeLoading(false);
        }
    }

    const fetchEmp4thFloor = async (data) => {
        try {
            setList4thFloorLoading(true);
            // setList4thFloor([])
            ws.emit("e_list_4th_floor", data, (action, err, response) => {
                if (err) throw new Error(response)
                setList4thFloor(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setList4thFloorLoading(false);
        }
    }
    const fetchEmp3rdFloor = async (data) => {
        try {
            setList3rdFloorLoading(true);
            // setList3rdFloor([])
            ws.emit("e_list_3rd_floor", data, (action, err, response) => {
                if (err) throw new Error(response)
                setList3rdFloor(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setList3rdFloorLoading(false);
        }
    }
    const fetchEmp2ndFloor = async (data) => {
        try {
            setList2ndFloorLoading(true);
            // setList2ndFloor([])
            ws.emit("e_list_2nd_floor", data, (action, err, response) => {
                if (err) throw new Error(response)
                setList2ndFloor(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setList2ndFloorLoading(false);
        }
    }

    const fetchEmpOtherFloor = async (data) => {
        try {
            setListOtherFloorLoading(true);
            // setListOtherFloor([])
            ws.emit("e_list_other_floor", data, (action, err, response) => {
                if (err) throw new Error(response)
                setListOtherFloor(response?.data || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setListOtherFloorLoading(false);
        }
    }


    return {
        fetchLogs,
        list,
        listLoading,
        fetchEmpNotInYet,
        listNotInYet,
        listNotInYetLoading,
        fetchEmpOutOfOffice,
        listOutOfOffice,
        listOutOfOfficeLoading,
        fetchEmp4thFloor,
        list4thFloor,
        list4thFloorLoading,
        fetchEmp3rdFloor,
        list3rdFloor,
        list3rdFloorLoading,
        fetchEmp2ndFloor,
        list2ndFloor,
        list2ndFloorLoading,
        fetchEmpOtherFloor,
        listOtherFloor,
        listOtherFloorLoading,

        fetchReportLogs,
        completeReport,
        reportLoading
    };
}
