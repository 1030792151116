import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { axiosGetCall } from '../../services/apisCall';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';
import moment from 'moment';


const columns = [
    { id: 'application_url', label: "Domain", minWidth: 170 },
    { id: 'company_brand_name', label: 'Brand Name', minWidth: 170 },
    { id: 'email_id', label: 'Email ID', minWidth: 150 },
    { id: 'support_contact_no', label: 'Support Contact No.', minWidth: 150 },
    { id: 'sales_email_id', label: 'Sales Email ID', minWidth: 150 },
    { id: 'company_name', label: 'Company Name', minWidth: 150 },
    { id: 'site_title', label: 'Site Title', minWidth: 150 },
    { id: 'create_date', label: 'Created At', minWidth: 100 },
    { id: 'products', label: 'Products', minWidth: 150 },
];

export default function PriceTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [networkError, setNetworkError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [load, setLoad] = React.useState(true)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getBrandingList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: rowsPerPage, flag: 1 };
            setNetworkError(false);
            const { result, totalCount } = await axiosGetCall("branding", params);
            setRows(result);
            setCount(totalCount);
        } catch (error) {
            setNetworkError(true);
            setRows([]);
            setCount(0);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            getBrandingList();
            setLoading(false);
        };

        fetchData();
    }, []);

    const style = {
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
        padding: '8px', height: '35px'
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 219px)' }}>
                <Table stickyHeader aria-label="sticky table" size="small" >
                    <TableHead sx={{ position: "sticky" }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth, padding: '8px', height: '35px' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <TableBody>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                {columns.map(({ id, align }) => (
                                    <TableCell
                                        key={id}
                                        align={align}
                                        sx={{
                                            color: 'grey.300',
                                            borderBottom: '1px solid',
                                            borderColor: 'grey.800',
                                        }}
                                    >
                                        <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.userName}>
                                    {columns.map((column) => {
                                        let value = row[column.id] !== undefined && row[column.id] !== null ? row[column.id] : '--';

                                        if (column.id === 'products' && Array.isArray(value)) {
                                            value = value.length > 0 ? value.join(', ') : '--';
                                        }

                                        if (column.id === 'create_date') {
                                            const newDate = moment.unix(value);
                                            value = newDate.format("DD MMM, YYYY");
                                        }

                                        return (
                                            <TableCell key={column.id} align="center"
                                                sx={
                                                    column.id === 'products'
                                                        ? {
                                                            color: 'grey.700',
                                                            borderBottom: '0.5px dashed',
                                                            borderColor: 'grey.300',
                                                            padding: '8px', height: '35px',
                                                            wordBreak: 'break-word',
                                                            maxWidth: '200px'
                                                        }
                                                        : {

                                                            color: 'grey.700',
                                                            borderBottom: '0.5px dashed',
                                                            borderColor: 'grey.300',
                                                            padding: '8px', height: '35px'
                                                        }
                                                }
                                            >
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>

                    )}
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper >
    );
}

