import React, { useEffect } from 'react';
import { Grid, ListItemButton as Item, Box, Typography, SvgIcon, ListItemIcon, Avatar, ListItemText, styled, Stack } from '@mui/material';
import LinearLoader from 'src/components/Loader/LinearLoader';
import Scrollbar from 'src/components/scrollbar';
import { formatDate } from 'src/utils/common';
import { FluentColorBuildingPeople24 } from 'src/assets/svgs/building';
import { useSocket } from 'src/context/SocketContext';
import { useEmployee } from './useEmployee';

const ListItem = styled(Item)(({ theme }) => ({
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    height: '60px'
}))

const EmpDashboard = () => {
    const ws = useSocket()
    const { fetchEmpNotInYet,
        listNotInYet,
        listNotInYetLoading, fetchEmpOutOfOffice, listOutOfOffice,
        listOutOfOfficeLoading, fetchEmp4thFloor,
        list4thFloor,
        list4thFloorLoading,
        fetchEmp3rdFloor,
        list3rdFloor,
        list3rdFloorLoading,
        fetchEmp2ndFloor,
        list2ndFloor,
        list2ndFloorLoading,
        fetchEmpOtherFloor,
        listOtherFloor,
        listOtherFloorLoading,
    } = useEmployee({ ws });

    useEffect(() => {
        fetchEmpNotInYet()
        fetchEmpOutOfOffice()
        fetchEmp4thFloor()
        fetchEmp3rdFloor()
        fetchEmp2ndFloor()
        fetchEmpOtherFloor()

        const iId = setInterval(() => {
            fetchEmpNotInYet()
            fetchEmpOutOfOffice()
            fetchEmp4thFloor()
            fetchEmp3rdFloor()
            fetchEmp2ndFloor()
            fetchEmpOtherFloor()
        }, 5000);
        return () => clearInterval(iId);
    }, [])

    function addTime(dateString) {
        let date = new Date(dateString);
        date.setMinutes(date.getMinutes() - 330); // 5 hours 30 minutes = 330 minutes
        return date.toISOString(); // Return updated date in ISO format
    }

    const getTimeDifference = (dateString) => {
        const pastDate = new Date(dateString);
        const now = new Date();

        const diffInMinutes = Math.floor((now - pastDate) / 60000); // Difference in minutes

        if (diffInMinutes < 1) return "Just now";

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        let timeAgo = "";
        if (hours > 0) timeAgo += `${hours} hr `;
        if (minutes > 0) timeAgo += `${minutes} min `;

        return timeAgo.trim();
    };

    function removeDuplicateWords(input) {
        if(!input) return ""
        const words = input.split(" "); // Split the string into words
        const uniqueWords = [...new Set(words)]; // Use Set to remove duplicates while keeping order
        return uniqueWords.join(" "); // Join the words back into a string
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            height: 'calc(100vh - 164px)'
        }}>
            <Grid container spacing={1.9} sx={{
                minHeight: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
                // height: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
                height: 'calc(100%/1.9 + 6px)',
                paddingLeft: '0px',
                paddingRight: '0px'
            }}>
                {/* 4th floor */}
                <Grid item sm={12} md={4} xl={4} xs={12} sx={{
                    borderRadius: '8px'
                }}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        4th Floor
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer',
                                }}
                            >

                                {list4thFloor?.length || 0}
                            </Typography>

                        </Box>
                        {list4thFloorLoading && <LinearLoader />}


                        {!list4thFloorLoading && <>

                            {
                                list4thFloor.length ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 131px)" }}>
                                        {
                                            list4thFloor.map(({ _source: { cardNumber, DepartmentName, FirstName, profileUrl, aDateTime } }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {FirstName?.trim().charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    display: "block",
                                                                    color: 'grey.800',
                                                                }}>
                                                                {removeDuplicateWords(FirstName)}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {DepartmentName}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {DepartmentName}
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                minWidth: '54px',
                                                                color: 'grey.600',
                                                            }}>
                                                            {formatDate(addTime(aDateTime)) || ""}
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            width: '70px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>
                                                                {/* {unreadCount} */}
                                                                {getTimeDifference(addTime(aDateTime))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found!
                                        </Typography>
                                    </Stack>
                            }
                        </>}
                    </Box>
                </Grid>

                {/* 3rd Floor */}
                <Grid item sm={12} md={4} xl={4} xs={12}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        3rd Floor
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer'
                                }}
                            // onClick={handleRedirectToChat}

                            >
                                {list3rdFloor?.length || 0}
                            </Typography>

                        </Box>
                        {list3rdFloorLoading && <LinearLoader />}


                        {!list3rdFloorLoading && <>

                            {
                                list3rdFloor.length > 0 ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 131px)" }}>
                                        {
                                            list3rdFloor.map(({ _source: { cardNumber, DepartmentName, FirstName, profileUrl, aDateTime } }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {FirstName?.trim().charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    display: "block",
                                                                    color: 'grey.800'
                                                                }}>
                                                                {removeDuplicateWords(FirstName)}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {DepartmentName}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {DepartmentName}
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                minWidth: '54px',
                                                                color: 'grey.600'
                                                            }}>
                                                            {formatDate(addTime(aDateTime)) || ""}
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            width: '70px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>
                                                                {/* {unreadCount} */}
                                                                {getTimeDifference(addTime(aDateTime))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found!
                                        </Typography>
                                    </Stack>
                            }
                        </>}

                    </Box>
                </Grid>

                {/* 2nd floor */}
                <Grid item sm={12} md={4} xl={4} xs={12}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        2nd Floor
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer'
                                }}
                            // onClick={handleRedirectToChat}

                            >
                                {list2ndFloor?.length || 0}
                            </Typography>

                        </Box>
                        {list2ndFloorLoading && <LinearLoader progress={10} />}


                        {!list2ndFloorLoading && <>

                            {
                                list2ndFloor.length > 0 ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 131px)" }}>
                                        {
                                            list2ndFloor.map(({ _source: { cardNumber, DepartmentName, FirstName, profileUrl, aDateTime } }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {FirstName?.trim().charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    display: "block",
                                                                    color: 'grey.800'
                                                                }}>
                                                                {removeDuplicateWords(FirstName)}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {DepartmentName}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {DepartmentName}
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                minWidth: '54px',
                                                                color: 'grey.600'
                                                            }}>
                                                            {formatDate(addTime(aDateTime)) || ""}
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            width: '70px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>
                                                                {/* {unreadCount} */}
                                                                {getTimeDifference(addTime(aDateTime))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found!
                                        </Typography>
                                    </Stack>
                            }
                        </>}

                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={1.9} sx={{
                minHeight: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
                // height: { xs: '766px', sm: '766px', md: '766px', lg: '377px', xl: '377px' },
                height: 'calc(100% / 2 - 70px)',
                paddingLeft: '0px',
                paddingRight: '0px'
            }}>
                {/* other floor members */}
                <Grid item sm={12} md={4} xl={4} xs={12} sx={{
                    borderRadius: '8px'
                }}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        Other Floors
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer',
                                }}
                            // onClick={handleRedirectToMail}
                            >

                                {listOtherFloor?.length || 0}
                            </Typography>

                        </Box>
                        {listOtherFloorLoading && <LinearLoader progress={10} />}


                        {!listOtherFloorLoading && <>

                            {
                                listOtherFloor.length > 0 ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 170px)" /* 295 */, }}>
                                        {
                                            listOtherFloor.map(({ _source: { cardNumber, DepartmentName, FirstName, profileUrl, aDateTime } }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {FirstName?.trim().charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    display: "block",
                                                                    color: 'grey.800'
                                                                }}>
                                                                {removeDuplicateWords(FirstName)}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {DepartmentName}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {DepartmentName}
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                minWidth: '54px',
                                                                color: 'grey.600'
                                                            }}>
                                                            {formatDate(addTime(aDateTime)) || ""}
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            width: '70px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>
                                                                {/* {unreadCount} */}
                                                                {getTimeDifference(addTime(aDateTime))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found!
                                        </Typography>
                                    </Stack>
                            }
                        </>}
                    </Box>
                </Grid>

                {/* outside office */}
                <Grid item sm={12} md={4} xl={4} xs={12}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        Outside Office
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer'
                                }}
                            // onClick={handleRedirectToChat}

                            >
                                {listOutOfOffice?.length || 0}
                            </Typography>

                        </Box>
                        {listOutOfOfficeLoading && <LinearLoader />}


                        {!listOutOfOfficeLoading && <>

                            {
                                listOutOfOffice.length > 0 ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 170px)" }}>
                                        {
                                            listOutOfOffice.map(({ _source: { cardNumber, DepartmentName, FirstName, profileUrl, aDateTime } = {} }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {FirstName?.trim().charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    width: "100%",
                                                                    display: "block",
                                                                    color: 'grey.800'
                                                                }}>
                                                                {removeDuplicateWords(FirstName)}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {DepartmentName}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {DepartmentName}
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                minWidth: '54px',
                                                                color: 'grey.600'
                                                            }}>
                                                            {formatDate(addTime(aDateTime)) || ""}
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end',
                                                            width: '70px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>
                                                                {/* {unreadCount} */}
                                                                {getTimeDifference(addTime(aDateTime))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found!
                                        </Typography>
                                    </Stack>
                            }
                        </>}


                    </Box>
                </Grid>

                {/* Not in yet */}
                <Grid item sm={12} md={4} xl={4} xs={12}>
                    <Box sx={{
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <Box sx={{
                            height: '64px',
                            width: '100%',
                            backgroundColor: 'grey.200',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                alignItems: 'center'
                            }}>
                                <SvgIcon sx={{
                                    width: '28px',
                                    height: '29px',
                                    color: 'primary.dark'
                                }}>
                                    <FluentColorBuildingPeople24 color={'currentColor'} />
                                </SvgIcon>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '16px',
                                    alignItems: 'flex-start'
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: 'grey.800'
                                        }}>
                                        Not In Yet
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            color: 'grey.600'
                                        }}>
                                        -
                                    </Typography>
                                </Box>

                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'primary.dark',
                                    cursor: 'pointer'
                                }}
                            >
                                {listNotInYet?.length || 0}
                            </Typography>

                        </Box>

                        {listNotInYetLoading && <LinearLoader />}


                        {!listNotInYetLoading && <>

                            {
                                listNotInYet.length > 0 ?
                                    <Scrollbar sx={{ height: "calc(100vh / 2 - 170px)" }}>
                                        {
                                            listNotInYet.map(({ _source: { cardNumber, group, userName, profileUrl } = {} }, index) => (
                                                <ListItem
                                                    sx={{ backgroundColor: 'grey.0', height: '30px' }}
                                                //  onClick={() => handleRedirectToChat(_id)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <Avatar
                                                            src={profileUrl || ""}
                                                            sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                backgroundColor: 'primary.main',
                                                                color: 'primary.100'
                                                            }}>
                                                            {userName?.charAt(0)?.toUpperCase() || 'U'}
                                                        </Avatar>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.800'
                                                                }}>
                                                                {userName}
                                                            </Typography>
                                                        }
                                                    // secondary={
                                                    //     <Typography
                                                    //         sx={{
                                                    //             fontSize: '12px',
                                                    //             fontWeight: 600,
                                                    //             color: 'grey.600'

                                                    //         }}>
                                                    //         {group}
                                                    //     </Typography>
                                                    // }
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        paddingRight: '12px',
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'flex-end'
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '122px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    color: 'grey.600'

                                                                }}>
                                                                {group}
                                                            </Typography>
                                                        </Box>
                                                        {/* <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: 500,
                                                                color: 'grey.600'
                                                            }}>
                                                        </Typography>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: '16px',
                                                            height: '16px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'success.300'
                                                        }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    fontWeight: 500,
                                                                    color: 'grey.600'
                                                                }}>

                                                            </Typography>
                                                        </Box> */}
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }

                                    </Scrollbar> :

                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                color: 'grey.500',
                                                maxWidth: '360px',
                                                textAlign: 'center'
                                            }}>
                                            No data found.
                                        </Typography>
                                    </Stack>
                            }
                        </>}

                    </Box>
                </Grid>
            </Grid >
        </Box>

    )
}

export default EmpDashboard