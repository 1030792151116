import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Chip } from "@mui/material";
import moment from 'moment';
import { useLocation } from "react-router-dom";

export const IncompleteKycTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const formattedData = Array.isArray(location.state) ? location.state : [location.state];
      setData([...formattedData]);
    }
    setLoading(false);
  }, [location.state]);

  const prepareDate = (value) => {
    const date = moment(value);
    const formattedDate = date.format('D MMM, YYYY');
    return `${formattedDate}`
  }


  return (
    <Paper sx={{ width: "100%", overflow: "hidden", p: 2, boxShadow: 3 }}>
      <Typography variant="h6" fontWeight="bold" textAlign="center" mb={2}>
        🚨 Incomplete KYC Records
      </Typography>

      {loading ? (
        <Typography textAlign="center">
          <CircularProgress />
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell sx={{ fontWeight: "bold" }}>User ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>KYC By</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>KYC Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Created At</TableCell>
              </TableRow>
            </TableHead>

            {data && Array.isArray(data) && data.length > 0 ? (
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.userId || "--"}</TableCell>
                    <TableCell>{row?.email || "--"}</TableCell>
                    <TableCell>{row?.kycBy || "--"}</TableCell>
                    <TableCell>
                      <Chip
                        label={row?.kycStatus==="pending"?'Pending':"--"}
                        sx={{
                          backgroundColor: 'rgba(209, 146, 68, 0.20)',
                          color: "#D19244",
                          fontWeight: "bold",
                          "&:hover": { backgroundColor: 'rgba(209, 146, 68, 0.20)' }
                        }}
                      />
                    </TableCell>
                    <TableCell>{row?.createdAt ? prepareDate(row.createdAt) : "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} align="center">No incomplete KYC records found.</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};
