import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';

import Logger from '../../lib/Logger';
// import { axiosVBGetCall } from '../../../services/vbApiCall';
import { renderErrorMessage } from '../../lib/toastMessage';
import { mainResselerId } from 'src/lib/Enums';
// import { TtsPlanTable } from './ttsPlanTable';
import { UserPlanTable } from './userPlanTable';
import { ResellerPlanTable } from './wpapiresellerPlanTable';
import { axiosGetCall } from 'src/services/apisCall';
import { type } from '@testing-library/user-event/dist/type';
const logger = new Logger("userPlansModal")

const whatsappPlans = ({ user }) => {
   
   
    const [credits, setCredits] = useState([]);
    const [assignedPlan, setAssignedPlan] = useState([]);
    const [loading, setLoading] = useState(false);

    const getUserUpdatedCredit = async () => {
        try {
            const params = { skip:"0", limit: "100" }
            const { data: userSpecificPlan = [] } = await axiosGetCall("CAGetPlanNCredits", { userId: user?.userId ,type: "user" });
            const creditsFilteredData = userSpecificPlan?.userPlans.filter((item) => item['plan_id'] !== undefined);
            setAssignedPlan(creditsFilteredData);
        } catch (error) {
            logger.error("DeliveryLogsTable.jsx ,getDeliveryLogs ,deliveryLogs api", error);
            renderErrorMessage(error?.message)
        }
    }
    const getCredits = async () => {
        try {
            
            let data;
            if (user?.userId === mainResselerId) {
                
                data = await axiosGetCall("CAGetAllPlanNCredits", {});
            } else {
                data = await axiosGetCall("CAGetPlanNCredits", { userId: mainResselerId });
            }
            const { userPlans: loginUserCredits } = data?.data || {};
            const creditsFilteredData = loginUserCredits?.filter((item) => item['plan_id'] !== undefined) || [];
            setCredits(creditsFilteredData);
        } catch (error) {
            logger.error("getCredits", error);
            renderErrorMessage(error?.message);
        }
    }

    const forceLoad = async () => {
        setLoading(true)
        await getUserUpdatedCredit();
        await getCredits();
        setLoading(false)
    }

    useEffect(() => {
        forceLoad();
        //eslint-disable-next-line
    }, [])


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            width: '100%',
            overflow: 'hidden',
            height: 'calc(70vh)',
            overflowY: 'scroll',
            scrollbarWidth: 'none'
        }}>
            {/* <Heading>
                TTS Plan
            </Heading> */}
            {/* <TtsPlanTable credits={credits} assignedPlan={assignedPlan} user={user} forceLoad={forceLoad} ttsData={ttsData} loading={loading} /> */}

            {/* <Heading>
                Currrent Assign Plan
            </Heading> */}
            <UserPlanTable planWiseCredits={assignedPlan} user={user} forceLoad={forceLoad} loading={loading} />


            {/* <Heading>
                Reseller login Plans
            </Heading> */}
            <ResellerPlanTable credits={credits} assignedPlan={assignedPlan} user={user} forceLoad={forceLoad} loading={loading} />

        </Box>
    )
}

export default whatsappPlans;