import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';
import BillingForm from '../sections/billing/billingForm';
import { SnackbarProvider } from 'src/components/snackbar';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { ConfirmDialog } from 'src/components/custom-dialog';

const MainSectionInner = styled('div')(({ theme }) => ({
    backgroundColor: "rgb(240, 240, 240)",
    padding: '30px 20px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // height: `calc(${window.innerHeight}px - 157px)`
}));

const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 20px 88px",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgb(240, 240, 240)"
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));


const BillingList = () => {
    return (
        <>
         <Helmet>
                <title> {`${pageTitle?.billing}`} </title>
            </Helmet>
        <MainSection>
            <HeadingSection>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        widht: '30px',
                        height: '30px',
                        mt: 0.5
                    }}>
                          <RequestQuoteOutlinedIcon />
                    </Box>
                    <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                    Create Quotation
                    </Typography>
                </Box>

            </HeadingSection>

            <MainSectionInner>
            <BillingForm />
            </MainSectionInner>
        </MainSection>
        </>
    )
}
export default BillingList;