import { useState, useCallback } from 'react';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { inputBaseClasses } from '@mui/material/InputBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useBoolean } from 'src/hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';

// import uuidv4 from 'src/utils/uuidv4';

// import { createColumn } from 'src/api/kanban';

import Iconify from 'src/components/iconify';
import ColumnSettingsModal from './new-column-modal';

// ----------------------------------------------------------------------

export default function KanbanColumnAdd({ addNewColumn, handleEmit, ticketType }) {
  const { enqueueSnackbar } = useSnackbar();
  const [columnName, setColumnName] = useState('');

  const openAddColumn = useBoolean();
  const newColumnModal = useBoolean()

  const handleChangeName = useCallback((event) => {
    setColumnName(event.target.value);
  }, []);

  // const description=(

  // )

  const handleCreateSupportColumn = useCallback((data) => {
    try {
      const { columnName, columnType, nextColumnId, templateId, time } = data;

      if (!columnName.trim()) return;

      const slaRule = { nextColumnId, time: parseInt(time), templateId: parseInt(templateId) }

      if (columnName) {
        handleEmit({ columnName, columnType, slaRule }, "addColumn", (err, data) => {
          if (!err) {
            setColumnName('');
            addNewColumn({ newColumn: data, callByPanel: true })
            openAddColumn.onFalse();
          }
          else {
            openAddColumn.onFalse();
            enqueueSnackbar(data, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "error"
            });
          }
        })
      }

    } catch (error) {
      console.error(error);
    }

  }, [columnName, openAddColumn])

  const handleCreateColumn = useCallback(async () => {
    try {
      if (!columnName.trim()) return;
      if (columnName) {
        handleEmit({ columnName }, "addColumn", (err, data) => {
          if (!err) {
            setColumnName('');
            addNewColumn({ newColumn: data, callByPanel: true })
            openAddColumn.onFalse();
          }
          else {
            openAddColumn.onFalse();
            enqueueSnackbar(data, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "error"
            });
          }
        })
      }
    } catch (error) {
      console.error(error);
    }
  }, [columnName, openAddColumn]);

  const handleKeyUpCreateColumn = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleCreateColumn();
      }
    },
    [handleCreateColumn]
  );

  return (
    <Paper sx={{ minWidth: 280, width: 280 }}>
      {openAddColumn.value ? (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <TextField
            autoFocus
            fullWidth
            placeholder="New section"
            value={columnName}
            onChange={handleChangeName}
            onKeyUp={handleKeyUpCreateColumn}
            sx={{
              [`& .${inputBaseClasses.input}`]: {
                typography: 'h6',
              },
            }}
          />
        </ClickAwayListener>
      ) : (
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          startIcon={<Iconify icon="mingcute:add-line" sx={{ mr: -0.5 }} />}
          onClick={() => ticketType === "lead" ? openAddColumn.onTrue() : newColumnModal.onTrue()}

        >
          Add Section
        </Button>
      )}
      {newColumnModal.value && <ColumnSettingsModal
        open={newColumnModal.value}
        onClose={() => newColumnModal.onFalse()}
        columns={[{ id: "col-1", name: "To Do" }, { id: "col-2", name: "In Progress" }]}
        onSave={(data) => handleCreateSupportColumn(data)}
      />}

    </Paper>
  );
}
