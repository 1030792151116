import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { axiosGetCall } from '../../services/apisCall';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';


const columns = [
    { id: 'userName', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'totalLeads', label: 'Total Leads', minWidth: 100 },
    { id: 'totalAcceptedLeads', label: 'Accepted', minWidth: 100 },
    { id: 'totalNoResponseLeads', label: 'Passed', minWidth: 100 },
    { id: 'totalPassedLeads', label: 'No Response', minWidth: 100 },
    // { id: 'averageResponseTime', label: 'Average Response Time', minWidth: 100 },
    // { id: 'pendingLeads', label: 'Pending Leads', minWidth: 100 },
    // { id: 'completedLeads', label: 'Completed Leads', minWidth: 100 },
    // {
    //     id: 'population',
    //     label: 'Revenue Earned',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'size',
    //     label: 'Targeted Revenue',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'density',
    //     label: 'Timeout',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toFixed(2),
    // },
];

export default function AnalysisTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [networkError, setNetworkError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const [count, setCount] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getLeadsAnalysisList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: rowsPerPage, flag: 1 };
            setNetworkError(false);
            const { result, count } = await axiosGetCall("getLeadsAnalysis", params);
            result?.sort((a, b) => b.totalLeads - a.totalLeads)
            setRows(result);
            setCount(count);
        } catch (error) {
            setNetworkError(true);
            setRows([]);
            setCount(0);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getLeadsAnalysisList();
            setLoading(false);
        };

        fetchData();
    }, [page, rowsPerPage]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 219px)' }}>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <TableBody>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                {columns.map(({ id, align }) => (
                                    <TableCell
                                        key={id}
                                        align={align}
                                        sx={{
                                            color: 'grey.300',
                                            borderBottom: '1px solid',
                                            borderColor: 'grey.800',
                                        }}
                                    >
                                        <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column) => {
                                        let value = 0;
                                        if (column.id === 'userName' || column.id === 'email' || column.id === "totalLeads" || column.id === "pendingLeads" || column.id === "averageResponseTime") {
                                            value = row[column.id] || 0;
                                        } else if (row[column.id]?.total !== undefined) {
                                            value = row[column.id].total;
                                        }
                                        return (
                                            <TableCell key={column.id} align="center">
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
