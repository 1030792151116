import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material"
import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import KanbanInputName from './kanban-input-name';
import ColumnSettingsModal from './new-column-modal';

// ----------------------------------------------------------------------

export default function KanbanColumnToolBar({
  column,
  columnName,
  onDeleteColumn,
  onClearColumn,
  onUpdateColumn,
  columnLength,
  ticketType,
  handleUpdateSupportColumn,
}) {
  const renameRef = useRef(null);

  const popover = usePopover();

  const confirmDialog = useBoolean();

  const [name, setName] = useState(columnName);
  const [showLength, setShowLength] = useState(true);
  const newColumnModal = useBoolean()

  useEffect(() => {
    if (popover.open && ticketType === "lead") {
      if (renameRef.current) {
        renameRef.current.focus();
      }
    }
  }, [popover.open]);

  useEffect(() => {
    setName(columnName);
  }, [columnName])

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleKeyUpUpdateColumn = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (renameRef.current) {
          renameRef.current.blur();
        }
        if (!name.trim()) {
          return setName(columnName)
        }
        onUpdateColumn(name);
      }
    },
    [name, onUpdateColumn]
  );

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ pt: 1.5, width: '100%' }} >
        <KanbanInputName
          inputRef={renameRef}
          placeholder="Section name"
          value={name}
          onChange={(e) => ticketType === "lead" && handleChangeName(e)}
          onKeyUp={handleKeyUpUpdateColumn}
          onFocus={() => setShowLength(false)}
          onBlur={() => setShowLength(true)}
          onClick={() => ticketType === "support" && newColumnModal.onTrue()}
          sx={{
            flexGrow: 1,
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
            position: 'relative',
            '&::after': showLength
              ? {
                content: `"(${columnLength})"`,
                color: '#000',
                fontSize: '0.975rem',
              }
              : {},
          }}
        />

        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-horizontal-fill" />
        </IconButton>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          ml: 1.5,
          width: 160,
        }}
      >
        {ticketType === "lead" && <MenuItem onClick={popover.onClose}>
          <Iconify icon="solar:pen-bold" />
          Rename
        </MenuItem>}

        {ticketType === "lead" && <MenuItem
          onClick={() => {
            onClearColumn();
            setName("")
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eraser-bold" />
          Clear
        </MenuItem>}

        <MenuItem
          onClick={() => {
            confirmDialog.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirmDialog.value}
        onClose={confirmDialog.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete column?
            <Box sx={{ typography: 'caption', color: 'error.main', mt: 2 }}>
              <strong> NOTE: </strong> All tasks related to this category will also be deleted.
            </Box>
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteColumn();
              confirmDialog.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      {newColumnModal.value && <ColumnSettingsModal
        open={newColumnModal.value}
        onClose={() => newColumnModal.onFalse()}
        columns={[{ id: "col-1", name: "To Do" }, { id: "col-2", name: "In Progress" }]}
        onSave={(data) => handleUpdateSupportColumn(data)}
        update={true}
        data={column}
      />}
    </>
  );
}

KanbanColumnToolBar.propTypes = {
  columnName: PropTypes.string,
  onClearColumn: PropTypes.func,
  onDeleteColumn: PropTypes.func,
  onUpdateColumn: PropTypes.func,
};
