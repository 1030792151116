import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
const logger = new Logger("kycListTable");
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import { CustomMenu } from '../../components/menu/customMenu';
import { Icon } from '@iconify/react';

// component
import { PaginationTable } from '../../components/tables/paginationTable';
import TableFilters from './tableFilter';

//mui 
import { TableCell, Box, List, ListItem, Button, TextField, FormControl, InputLabel, Select, MenuItem, Menu, styled } from '@mui/material';

//mui icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { configFile } from '../../config';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { checkButtonAccess } from 'src/utils/common';
import { useNavigate } from 'react-router-dom';
import UserDetails from './details';
import BroadcastActions from './broadcast/broadcastActions';
import WhatsappActions from '../whatsapp/whatsappAction';

const columns = [
    { id: 'userId', label: 'User ID', minWidth: 110, align: 'center' },
    { id: 'name', label: 'Name', minWidth: 110, align: 'center' },
    { id: 'email', label: 'Email', minWidth: 110, align: 'center' },
    { id: 'password', label: 'Password', minWidth: 110, align: 'center' },

    { id: 'mobile', label: 'Mobile', minWidth: 110, align: 'center' },
    { id: "accountManagerName", label: "Account Manager", minWidth: 110, align: "center" },
    { id: "createdBy", label: "Created From", minWidth: 110, align: "center" },
    // { id: "isGoogleLogin", label: "Google Login", minWidth: 110, align: "center" },
    { id: "verified", label: "Verified", minWidth: 110, align: "center" },
    { id: 'createdAt', label: 'Created Date', minWidth: 110, align: 'center' },
    { id: 'permission', label: 'Permission', minWidth: 110, align: 'center' },
    { id: 'actions', label: 'Action', minWidth: 20, align: 'center' },
];

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    // color: theme.palette.grey[200],
    gap: '8px'
}))

export const ConsoleUserListTable = () => {
    const { URL_MAPPER: { console: consoleUrl } = {} } = configFile
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)

    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState("")
    const [openChangeAm, setOpenChangeAm] = useState(false);

    const [userDetails, setUserDetails] = useState({});
    const [openDetails, setOpenDetails] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [allAdmin, setAllAdmin] = useState([]);
    const [adminEmail, setAdminEmail] = useState("");

    const [newAdminEmail, setNewAdminEmail] = useState("")
    const navigate = useNavigate();

    const fetchAllAdmin = async () => {
        try {
            const { amEmail, result } = await axiosGetCall("getAllAdmin", { userId: selectedUser?.userId })
            setAllAdmin(result);
            setAdminEmail(amEmail);
        } catch (error) {
            console.error("error in fetching admin", error)
        }


    }

    const updateUserAdmin = async () => {
        try {
            const { message } = await axiosPostCall("updateUserAdmin", { userId: selectedUser?.userId, amEmail: newAdminEmail })
            renderSuccessMessage(message);
            setOpenChangeAm(false);
            setNewAdminEmail("");
            handleClose();
            setLoad(true);
        } catch (error) {
            console.error("error in updating admin", error);
            renderErrorMessage(error?.message);

        }

    }

    const handleChangeAmClick = async () => {
        await fetchAllAdmin();
        setOpenChangeAm(true);
    }

    const handleSelectChange = (e) => {
        setNewAdminEmail(e.target.value);
    }

    const handleUserDetailsUpdate = () => {
        return navigate("/updateUser", { state: { userDetails: selectedUser, edit: true } })
    }

    const handleChange = (value) => setSearch(value)

    const getConsoleUserList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: rowsPerPage }
            if (search) params.email = search
            setNetworkError(false)
            const { usersList, count } = await axiosGetCall("consoleUsersList", params)
            setRows(usersList)
            setCount(count)
        } catch (error) {
            logger.error("ConsoleUserList.jsx ,getConsoleUserList ,consoleUsersList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (load) {
            setLoading(true)
            getConsoleUserList()
            setLoad(false)
        }
    }, [load])

    const handleClick = (event, user) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refLogin = async () => {
        try {
            setAnchorEl(null);
            const { token, sessionToken, exp } = await axiosPostCall("consoleReferenceLogin", { userId: selectedUser?.email })
            // renderSuccessMessage("Please wait, redirecting...");
            const finalUrl = consoleUrl + "/customSession?token=" + token + "&exp=" + exp + "&sessionToken=" + sessionToken;
            window.open(finalUrl);
            return
        } catch (error) {
            logger.error("refLogin error: ", error);
            return renderErrorMessage(error?.message || "Internel server error!")
        }
    };

    const handleViewDetailsClick = async () => {
        try {
            const { userDetails } = await axiosGetCall("consoleUserDetails", { email: selectedUser?.email });
            setUserDetails(userDetails);
            setOpenDetails(true);

        } catch (error) {
            logger.error(error);
        }
    }

    const updateForceVerifyPermission = async () => {
        try {
            setAnchorEl(null);
            const { message } = await axiosPostCall("updateForceVerifyPermission", { updatedPermission: !selectedUser?.allowToForceVerify, email: selectedUser?.email })
            renderSuccessMessage(message);
            getConsoleUserList();

        } catch (error) {
            logger.error("refLogin error: ", error);
            return renderErrorMessage(error?.message || "Internel server error!")
        }
    }

    const tableFilter = <TableFilters search={search} handleChange={handleChange} setLoad={setLoad} count={count} setPage={setPage} />

    const ActionJsx = (id, align, value, ...other) => {
        const user = other[3]

        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.300',
                    size: 'small'
                }}>
                <MoreVertIcon
                    sx={{ color: 'grey.700', cursor: 'pointer', height: '18px', width: '18px' }}
                    aria-controls={open ? 'filters-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => handleClick(e, user)}
                />
            </TableCell>
        )
    }

    const amActions = () => {

        return (
            <Button variant='outlined' color='primary' onClick={updateUserAdmin} disabled={!checkButtonAccess("updateConsoleAdmin", "w")} >
                Update
            </Button>
        )
    }

    const AmContant = () => {

        return (
            <Box sx={{
                display: 'flex',
                width: '100%',
                gap: '17px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '8px'
            }}>
                {adminEmail && <>
                    <TextField id="outlined-basic" value={adminEmail} label="Old Account Manager" variant="outlined" fullWidth disabled />

                    <Icon icon="ion:arrow-down-outline" style={{ color: 'blue', width: '20px', height: '27px' }} />
                </>}

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select New Account Manager</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newAdminEmail}
                        label="Select New Account Manager"
                        onChange={handleSelectChange}
                    >
                        {allAdmin.map((email) => {
                            return <MenuItem key={email} value={email}>{email}</MenuItem>

                        })}

                    </Select>
                </FormControl>
            </Box>
        )
    }

    const Content = () => {
        const [productAnchorEl, setProductAnchorEl] = useState(null);
        const [currentActionProduct, setCurrentActionProduct] = useState(null);
        const openProductMenu = Boolean(productAnchorEl);

        const handleCloseProductMenu = () => {
            setProductAnchorEl(null);
            handleClose()
        }

        const handleClickProductMenu = (id) => {
            setCurrentActionProduct(id);
            setProductAnchorEl(null);
        }

        return (

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}>


                <List>
                    <ListItem sx={{
                        display: 'flex',
                        gap: '7px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        onClick={() => handleViewDetailsClick()}
                    >
                        <Icon icon="pajamas:details-block" style={{ color: 'green', width: '16px', height: '16px' }} />
                        Details
                    </ListItem>
                    {checkButtonAccess("refLogin", "w") && <ListItem sx={{
                        display: 'flex',
                        gap: '7px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        onClick={() => refLogin()}
                    >
                        <Icon icon="ant-design:login-outlined" style={{ color: 'blue' }} />
                        Ref-login
                    </ListItem>}

                    {checkButtonAccess("createConsoleUser", "w") && <ListItem sx={{
                        display: 'flex',
                        gap: '7px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        onClick={() => handleUserDetailsUpdate()}
                    >
                        <Icon icon="clarity:update-line" style={{ color: 'green', width: '16px', height: '16px' }} />
                        Update
                    </ListItem>}

                    {selectedUser?.permission === "l4" && checkButtonAccess("forceVerify", "r") && <ListItem sx={{
                        display: 'flex',
                        gap: '7px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        onClick={() => updateForceVerifyPermission()}
                    >
                        <Icon icon="bitcoin-icons:verify-outline" style={{ color: 'blue', width: '25px', height: '27px' }} />
                        {selectedUser?.allowToForceVerify ? "Disable" : "Enable"} force verify action
                    </ListItem>}

                    {checkButtonAccess("updateConsoleAdmin", "r") && <ListItem sx={{
                        display: 'flex',
                        gap: '12px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        // onClick={() => updateForceVerifyPermission()}
                        onClick={() => handleChangeAmClick()}
                    >
                        <Icon icon="radix-icons:update" style={{ color: 'blue', width: '16px', height: '16px' }} />
                        Assign AM
                    </ListItem>}

                    <ListItem sx={{
                        display: 'flex',
                        gap: '12px',
                        fontWeight: 400,
                        color: 'grey.700',
                        '&:hover': {
                            color: 'grey.800',
                            backgroundColor: 'grey.300',
                            cursor: "pointer"
                        }
                    }}
                        // onClick={() => updateForceVerifyPermission()}
                        onClick={(e) => { setProductAnchorEl(e.currentTarget) }}
                    >
                        <Icon icon="bitcoin-icons:transactions-outline" style={{ color: 'blue', width: '18px', height: '20px' }} />
                        Product Actions
                    </ListItem>
                </List>
                <Menu
                    id="menu-productActions"
                    anchorEl={productAnchorEl}
                    open={openProductMenu}
                    onClose={handleCloseProductMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'left',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{ padding: '0px' }}
                >
                    {selectedUser?.products?.length ?
                        selectedUser?.products.map(pName => {
                            if (pName === "Broadcast" && !(selectedUser?.voiceInfo && selectedUser?.voiceInfo?.userId)) return null
                            return (
                                <CustomMenuItem onClick={() => handleClickProductMenu(pName)} key={pName}>
                                    {pName === "WaApi" ? "Whatsapp" : pName} Actions
                                </CustomMenuItem>
                            )
                        })

                        : "No products found!"}
                </Menu>

                {currentActionProduct === "Broadcast" && <BroadcastActions
                    currentActionProduct={currentActionProduct}
                    setCurrentActionProduct={setCurrentActionProduct}
                    user={selectedUser}
                />}

                {currentActionProduct === "WaApi" && <WhatsappActions
                    currentActionProduct={currentActionProduct}
                    setCurrentActionProduct={setCurrentActionProduct}
                    user={selectedUser}
                />}


                <ConfirmDialog
                    open={openChangeAm}
                    onClose={() => {
                        setOpenChangeAm(false);
                        setNewAdminEmail("");
                        handleClose();
                    }}
                    title={"Change Account Manager"}
                    content={<AmContant userId={selectedUser?.userId} />}
                    action={amActions()}
                />
                <UserDetails
                    data={userDetails}
                    openDetails={openDetails}
                    setOpenDetails={setOpenDetails}
                    handleClose={handleClose}
                />
            </Box >
        )
    }

    const ActionModals = () => {
        return (
            <CustomMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                Content={Content} />
        )
    }
    return (
        <>
            {/* <ToastContainer /> */}
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={columns}
                handleChange={handleChange}
                search={search}
                calculatedHeight={288}
            />
        </>
    )
}
