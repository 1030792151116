import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ReactHtmlParser from 'react-html-parser';

import { fDateTime, fToNow } from 'src/utils/format-time';

import Image from 'src/components/image';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box, Button, ButtonBase, Collapse, Link, ListItemText, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import FileThumbnail from 'src/components/file-thumbnail';
import { useBoolean } from 'src/hooks/use-boolean';
import JSZip from 'jszip';
import { useEffect, useRef } from 'react';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

// ----------------------------------------------------------------------

const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const handleDownloadAttachment = (file) => {
  const fileExtension = getFileExtension(file.contentType);
  const fileName = `attachment.${fileExtension}`;
  const blob = new Blob([file.content], { type: file.contentType });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName//file.name;  
  a.click();

  window.URL.revokeObjectURL(url);
};

const handleDownloadZip = async (files) => {
  const zip = new JSZip();

  files.forEach((file) => {
    zip.file(file.filename, file.content);
  });

  try {
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "attachments.zip");
  } catch (error) {
    console.error("Error generating ZIP file", error);
  }
};

const getFileExtension = (mimeType) => {
  const mimeMap = {
    'text/plain': '.txt',
    'application/json': '.json',
    'application/pdf': '.pdf',
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'audio/wav': '.wav',
    "application/zip": 'zip'
  };
  return mimeMap[mimeType] || 'txt';
};

export default function KanbanDetailsCommentList({ mailThread }) {


  const RenderAttachments = ({ attachments }) => {
    const showAttachments = useBoolean();
    return (
      <Stack
        spacing={1}
        sx={{
          p: 1,
          borderRadius: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ButtonBase
            onClick={showAttachments.onToggle}
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              borderRadius: 0.5,
            }}
          >
            <Iconify icon="eva:attach-2-fill" sx={{ mr: 0.5 }} />
            {attachments?.length} attachments
            <Iconify
              icon={
                showAttachments.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
              }
              width={16}
              sx={{ ml: 0.5 }}
            />
          </ButtonBase>

          <Button startIcon={<Iconify icon="eva:cloud-download-fill" />} onClick={() => handleDownloadZip(mail?.attachments)}>Download</Button>
        </Stack>

        <Collapse in={showAttachments.value} unmountOnExit timeout="auto">
          <Stack direction="row" flexWrap="wrap" spacing={1}>
            {attachments.map((attachment, i) => (
              <Stack
                key={attachment.id || i + 1}
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 180,
                  height: 100,
                  flexShrink: 0,
                  borderRadius: 1,
                  overflow: 'hidden',
                  position: 'relative',
                  backgroundColor: 'background.neutral',
                  border: '0.5px dashed',
                  color: 'grey.500'
                }}
              >
                <FileThumbnail
                  tooltip
                  imageView
                  file={`data:${attachment.contentType};base64,${arrayBufferToBase64(attachment.content)}`}
                  attachFile={`data:${attachment.contentType};base64,${arrayBufferToBase64(attachment.content)}`}
                  onDownload={() => handleDownloadAttachment(attachment)}
                  contentType={attachment.contentType}
                  filename={attachment.filename}
                  sx={{ width: 54, height: 54 }}
                />
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  const scrollbarRef = useRef();

  // Scroll to bottom when mailThread changes
  useEffect(() => {
    if (scrollbarRef.current) {
      const scrollElement = scrollbarRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [mailThread]);


  const renderContent = (
    <Box
      sx={{
        // py: 3,
        flexGrow: 1,
        overflow: { xs: 'auto', md: 'hidden' },
        // padding: '10px 30px 30px 30px'
        // height:"10000px"
      }}
    >
      <Scrollbar ref={scrollbarRef} >


        {!!mailThread?.length &&
          /* [...Object.values(mail?.thridData), ...Object.values(mail?.sentMails)].sort((a, b) =>
            new Date(a.date) - new Date(b.date)
          ) */mailThread.map(({ message, textAsHtml, html, from, to, cc, date, attachments, attributes = {}, id, reply: isReply
          , messageId, subject, text, folder
        }, i) => (
          <Box key={i + 1} sx={{
            // borderBottom: '0.5px solid',
            // borderColor: 'grey.300',
            // paddingBottom: 1,
            // paddingTop: '10px',
          }}>
            <Accordion defaultExpanded>

              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header" u
              >

                <Stack spacing={2} direction="row" flexShrink={0} alignItems={"center"} width={'100%'}>
                  <Stack
                    flexShrink={1}
                    direction="row"
                    alignItems="center"
                    sx={{
                      p: (theme) => theme.spacing(1, 1, 1, 1),
                      flexGrow: 1
                    }}
                  >
                    <Avatar
                      alt={from?.value?.[0]?.name}
                      src={from?.value?.[0]?.avatarUrl ? `${from?.value?.[0]?.avatarUrl}` : ''}
                      sx={{ mr: 2 }}
                    >
                      {from?.value?.[0]?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>

                    <ListItemText
                      primary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px'
                        }}>
                          <Typography variant="body2" sx={{ color: 'grey.800', fontWeight: 400 }}>
                            {from?.value?.[0]?.name}
                          </Typography>
                          <Box component="span" sx={{ typography: 'body2', color: 'grey.1100', fontWeight: 300, fontSize: '14px' }}>
                            {` <${from?.value?.[0]?.address}>`}
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px'
                        }}>
                          <Typography variant="body2" sx={{ color: 'grey.800', fontWeight: 300 }}>
                            {`To: `}
                          </Typography>
                          {to?.value.concat(cc?.value || []).map((person, index) => (
                            <Link key={person.address} sx={{ color: 'grey.1100', fontWeight: 300 }}>
                              {to?.value.concat(cc?.value || []).length - 1 === index ? `${person.address}` : `${person.address}, `}
                            </Link>
                          ))}
                        </Box>
                      }
                      secondaryTypographyProps={{
                        mt: 0.5,
                        noWrap: true,
                        component: 'span',
                        typography: 'caption',
                      }}
                    />
                  </Stack>

                  <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: (theme) => theme.spacing(1) }}>
                    {/* <Stack direction="row" alignItems="center" justifyContent="flex-end">
                  {!!attachments.length && <IconButton size="small">
                    <Iconify width={18} icon="material-symbols-light:attachment" />
                  </IconButton>}

                </Stack> */}

                    <Typography variant="caption" noWrap sx={{ color: 'grey.1100', mr: '5px' }}>
                      {date && fDateTime(date)}
                    </Typography>

                    {/* <Star
                  flags={attributes?.flags}
                  markFlag={markFlag}
                  uid={attributes?.uid}
                  id={mail?.thridId}
                  folder={folder}
                /> */}

                    {/* <Tooltip title={"Reply"} placement={'bottom'}>
                  <IconButton
                    size="small"
                    onClick={() => updateMailData({ reply: true, updateId: attributes?.uid, rootId: mail?.id, additionalData: {}, action: 'add' })}
                    sx={{ ml: '-8px' }}
                  >
                    <Iconify icon="solar:reply-bold" />
                    <SvgIcon sx={{
                      color: 'grey.700',
                      width: '16px',
                      height: '16px',
                    }}>
                      <CornerUpLeftLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </IconButton>
                </Tooltip> */}
                  </Stack>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Box sx={{ ml: 7, mr: 7, color: 'text.primary' }}>
                  {message ? ReactHtmlParser(message) : ReactHtmlParser(textAsHtml || html)}
                </Box>
              </AccordionDetails>

              {/* {(!!attachments.length && <Stack sx={{ p: 1 }}> {renderAttachments} </Stack>)}

              

            {(isReply && !mail?.isLast) && <MailReply
              mail={{ message, from, to, cc, date, attachments, attributes, id, thridId: mail?.thridId, reply, messageId, subject }}
              signature={signature}
              sendReply={sendReply}
              isReplyToAll={replyToAll}
              updateMailData={updateMailData}
              rootId={mail?.thridId}
            />} */}

              {(!!attachments.length && <Stack sx={{ p: 1 }}>
                {/* {renderAttachments(attachments)} */}
                <RenderAttachments attachments={attachments} />
              </Stack>)}

            </Accordion>
          </Box>
        ))
        }


        {/* {mail?.folder === 'Sent' && ReactHtmlParser(mail?.textAsHtml)} */}

        {/* {(mail?.isLast) && <MailReply
          mail={[...Object.values(mail?.thridData || {})].sort((a, b) => new Date(a.date) - new Date(b.date))?.at(-1)}
          signature={signature}
          sendReply={sendReply}
          isReplyToAll={false}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
        />} */}

        {/* {(mail?.isReplyAll) && <MailReply
          mail={mail}
          signature={signature}
          sendReply={sendReply}
          isReplyToAll={mail?.isReplyAll}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
        />} */}

        {/* {(!reply && !forword) && replyNforword} */}


        {/* {(mail?.forward || forword) && <ForwordMail
          mail={mail}
          signature={signature}
          sendReply={sendReply}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
          setForword={setForword}
        />} */}
      </Scrollbar>
    </Box>
  );


  return <>
    {renderContent
    }
  </>
}

KanbanDetailsCommentList.propTypes = {
  comments: PropTypes.array,
};
