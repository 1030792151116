import React from 'react';
import EmpDashboard from 'src/sections/employee/empDashboard';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, TextField, InputAdornment } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const MainSection = styled('div')(() => ({
    width: '100%',
    padding: '64px 20px 20px 88px',
    backgroundColor: "rgb(240, 240, 240)",
    // height: '100vh'
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "0px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const EmployeeDashboard = () => {

    const navigate = useNavigate()

    return (
        <MainSection sx={{ pt: '84px' }}>
            <HeadingSection>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        widht: '30px',
                        height: '30px',
                        mt: 0.5
                    }}>
                        {/* <AssessmentIcon /> */}
                        <Iconify icon="cbi:hr1" />
                        {/* <iconify-icon icon="twemoji:waving-hand" width="36" height="36"></iconify-icon> */}
                    </Box>
                    <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                        HRMS {/* {nameInfo?.name} */}
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    alignItems: 'center',
                }}
                >
                    <Button color='primary' variant='outlined' onClick={() => navigate("/hrmsList")}>
                        Analytics
                    </Button>

                   {/*  <TextField
                        size='small'
                        id="outlined-search"
                        label=""
                        variant="outlined"
                        placeholder='Search with name or email'
                        sx={{minWidth: '300px'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                </Box>

            </HeadingSection>

            <EmpDashboard />
        </MainSection>
    )
}

export default EmployeeDashboard