import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';
import BillIcon from '../assets/svgs/bill';
import BillingForm from '../sections/billing/billingList';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import { useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { checkButtonAccess } from '../utils/common';
import BarChartIcon from '@mui/icons-material/BarChart';
const createQuotation = async (navigate) => {
    navigate("/create_quotation")
}

const quotationAnalysis = async (navigate) => {
    navigate("/quotation_analysis")
}

const MainSection = styled('div')(() => ({
    width: '100%',
    padding: '64px 20px 0px 88px',
    backgroundColor: "rgb(240, 240, 240)",
    height: '100vh'
}));

const MainSectionInner = styled('div')(({ theme }) => ({
    // backgroundColor: "#fff",
    // padding: '30px 20px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // height: `calc(${window.innerHeight}px - 157px)`
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const Billing = () => {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.billing}`} </title>
            </Helmet>
            <MainSection>
                <HeadingSection>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            widht: '30px',
                            height: '30px',
                            mt: 1.3
                        }}>
                            <BillIcon />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Billing
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            gap: "10px",
                            position: 'absolute',
                            right: 0,
                            marginRight: '30px',

                        }}>
                            {checkButtonAccess("sendInvoice", "w") && <Button
                                variant='outlined'
                                onClick={() => createQuotation(navigate)}
                                size='small'
                                sx={{
                                    backgroundColor: 'rgb(33, 43, 54)',
                                    color: 'grey.100',
                                    p: '0px 20px 0px 20px',
                                    '&:hover': {
                                        backgroundColor: 'rgb(69, 79, 91)'
                                    }
                                }}
                            ><AddRoundedIcon sx={{ margin: "3px" }} />
                                Create Quotation</Button>}
                            {checkButtonAccess("sendInvoice", "w") && <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => quotationAnalysis(navigate)}
                                size='small'
                            ><BarChartIcon sx={{ margin: "4px" }} />
                                Quotation Analysis</Button>}
                        </Box>
                    </Box>

                </HeadingSection>

                <MainSectionInner>
                    <BillingForm />
                </MainSectionInner>
            </MainSection>
        </>
    )
}

export default Billing