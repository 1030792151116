
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { axiosGetCall } from 'src/services/apisCall';
import InfiniteScroll from 'react-infinite-scroll-component';
import { validateDate } from '@mui/x-date-pickers/internals';
import moment from 'moment';

// ----------------------------------------------------------------------

export default function KanbanActivityDialog({ taskId, user }) {
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [from, setFrom] = useState(0);

    const DateComponent = ({ date }) => {
        const newDate = moment(date);
        const formattedDate = newDate.format('[at] DD MMMM YYYY,  HH:mm A');

        return (
            <Typography>
                <strong>{formattedDate}</strong>
            </Typography>
        );
    };
    const toCapitalLetters = (str) => {
        return str ? str.toUpperCase() : "";
    };
    const nameJsx = (value) => {
        return (

            <Box key={validateDate._id} sx={{ width: "100%", }}>

                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName}</strong> {toCapitalLetters(value.action)} the <strong>{value.type}</strong>
                    </Typography>
                    <DateComponent date={value.createdAt} />
                    {(value.oldValue || value.newValue) ? (
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            {value.oldValue && (
                                <Typography variant="span" sx={{ backgroundColor: "var(--ds-background-information, #DEEBFF)", color: "var(--ds-text-information, #0052CC)" }}>
                                    {value.oldValue}
                                </Typography>
                            )}
                            to
                            {value.newValue && (
                                value.action === 'Calling' ? (
                                    <Typography variant="span" sx={{ backgroundColor: "var(--ds-background-success, #E3FCEF)", color: "var(--ds-text-success, #006644)" }}>
                                        {user}
                                    </Typography>
                                ) : (
                                    <Typography variant="span" sx={{ backgroundColor: "var(--ds-background-success, #E3FCEF)", color: "var(--ds-text-success, #006644)" }}>
                                        {value.newValue}
                                    </Typography>
                                )
                            )}
                        </Box>
                    ) : null}

                </Box>

            </Box>

        )
    }

    const pass = (value) => {
        return (
            <Box key={validateDate._id} sx={{ width: "100%" }}>
                <Typography variant="body1" color="textPrimary">
                    <strong>{value.userName || value.newValue} </strong>
                    <strong style={{ backgroundColor: "#FFCCCB", color: "red", padding: "2px 4px",margin:'4px' }}>
                        {value.type}
                    </strong>
                     this leads
                </Typography>
                <DateComponent date={value.updatedAt} />
            </Box>
        );
    };
    

    const rankJsx = (value) => {
        return (

            <Box key={validateDate._id} sx={{ width: "100%", }}>

                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName}</strong> {toCapitalLetters(value.action)} the <strong>{value.type}</strong>
                    </Typography>
                    <DateComponent date={value.createdAt} />

                </Box>

            </Box>

        )
    }
    
    const assigneeJsx = (value) => {
        return (
            <Box key={value?._id} sx={{ width: "100%" }}>
                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName || "Unknown User"}</strong>{" "}
                        <span
                            style={{
                                color: value.action === "assigned" ? "green" : "red",
                                backgroundColor: value.action === "assigned" ? "#E3FCEF" : "#FFCCCB",
                                padding: "2px 6px",
                                borderRadius: "4px",
                            }}
                        >
                            {toCapitalLetters(value.action) || "performed"}
                        </span>{" "}
                        
                    </Typography>
                    <Typography
                        variant="span"
                        sx={{
                            color: "primary.main",
                        }}
                    >
                        {value.newValue || "No value provided"}
                    </Typography>
                    <DateComponent date={value.updatedAt || new Date().toISOString()} />
                </Box>
            </Box>
        );
    };

    const acceptedJsx = (value) => {
        return (
            <Box key={value?._id} sx={{ width: "100%" }}>
                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName || value.newValue}</strong>{" "}
                        <span
                            style={{
                                color: value.action === "accepted" || value.action === "ACCEPTED" ? "green" : "red",
                                backgroundColor: value.action === "accepted" || value.action === "ACCEPTED"? "#E3FCEF" : "#FFCCCB",
                                padding: "2px 6px",
                                borderRadius: "4px",
                            }}
                        >
                            {toCapitalLetters(value.action) || "performed"}
                        </span>{" "}
                        
                    </Typography>
                    <DateComponent date={value.updatedAt || new Date().toISOString()} />
                </Box>
            </Box>
        );
    };
    
    const commentJsx = (value) => {
        return (

            <Box key={validateDate._id} sx={{ width: "100%", }}>

                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName}</strong> {toCapitalLetters(value.action)} the <strong>{value.type}</strong>
                    </Typography>

                    <DateComponent date={value.createdAt} />

                </Box>

            </Box>

        )
    }

    const dueJsx = (value) => {
        const [oldDate1, oldDate2] = value.oldValue;
        const [newDate1, newDate2] = value.newValue;
        const date2 = oldDate2 ? moment.unix(oldDate2 / 1000).format("DD MMMM YYYY") : null;
        const date4 = newDate2 ? moment.unix(newDate2 / 1000).format("DD MMMM YYYY") : null;

        return (

            <Box key={validateDate._id} sx={{ width: "100%", }}>

                <Box>
                    <Typography variant="body1" color="textPrimary">
                        <strong>{value.userName}</strong> {toCapitalLetters(value.action)} the <strong>{value.type} date</strong>
                    </Typography>

                    <DateComponent date={value.createdAt} />
                    {date2 && (
                        <Typography>
                            from
                            <Box component="span" sx={{ backgroundColor: "#ffcccc", color: "red", padding: "0 4px", marginLeft: '10px' }}>
                                {date2}
                            </Box>
                        </Typography>
                    )}

                    {date4 && (
                        <Typography sx={{ display: "flex", alignItems: "center" }}> to
                            <Box component="span" sx={{ backgroundColor: "var(--ds-background-success, #E3FCEF)", color: "var(--ds-text-success, #006644)", padding: "0 4px", marginLeft: "10px" }}>
                                {date4}
                            </Box>
                        </Typography>
                    )}

                </Box>
            </Box>
        )
    }


    const activityMapper = {
        name: nameJsx,
        assignee: assigneeJsx,
        accepted: acceptedJsx,
        due: dueJsx,
        priority: nameJsx,
        description: nameJsx,
        text: commentJsx,
        image: commentJsx,
        status: nameJsx,
        rank: rankJsx,
        clickToCall: nameJsx,
        AUTO_PASS: pass,
        mrr: nameJsx,
        removed:assigneeJsx,
        ACCEPTED:acceptedJsx,
        PASSED:pass
    }

    const fetchActivityLogs = async () => {
        try {
            const params = { taskId, from: from, size: 10 }
            const { result } = await axiosGetCall("getCrmActivityLogs", params)
            setFrom((value) => value + 10);
            setList([...list, ...result])

            if (result.length < 5) {
                setHasMore(true)
            }
        } catch (error) {
            console.error(error)
        }
    }
    
    useEffect(() => {
        fetchActivityLogs()
    }, [])
    

    return (

        list.length > 0 && (
            <InfiniteScroll
                dataLength={list?.length}
                next={fetchActivityLogs}
                hasMore={hasMore}
                height={Math.min(list?.length * 80, 420)}
            >
                <Box sx={{ display: "flex", flexDirection: 'column', gap: "20px" }}>
                    {list?.map((value, index) => {
                        const activityFunction = activityMapper[value?.type];

                        if (typeof activityFunction === 'function') {
                            return (
                                <>
                                    {activityFunction(value)}
                                </>
                            );
                        } else {
                            console.error(`No function found for type: ${value?.type}`);
                            return null;
                        }
                    })}
                </Box>
            </InfiniteScroll>
        )
    );
}
