import React, { useState, useEffect, useContext } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, TextField, Divider, Select, MenuItem, FormControl, InputLabel, Autocomplete, Grid, CircularProgress, Stack, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PreviewComponent from './preview';
import { useBoolean } from 'src/hooks/use-boolean';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { useNavigate, useLocation } from 'react-router-dom';
import { TokenContext } from '.././../context/TokenContext'
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSocket } from "src/context/SocketContext";
import { debounce } from "lodash";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const RowBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    width: '60%'
}))

const RowInnerBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: 'calc(100%/2 - 5px)',
    gap: '5px'
}))

const Key = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[600],
}))
const BillingForm = () => {
    const location = useLocation();
    const { data } = location.state || {};
    const theme = useTheme();
    const { decodedToken } = useContext(TokenContext);
    const confirm = useBoolean();
    const navigate = useNavigate()
    const [isFocused, setIsFocused] = useState(false);
    const [approval, setApproval] = useState(false);
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [toAddressData, setToAddressData] = useState(false)
    const [editData, setEditData] = useState(true);
    const [kycUserdata, setKycUserDta] = useState([]);
    const socket = useSocket();
    const [companyDetails, setCompanyDetails] = useState([]);
    const [order, setOrder] = useState({
        fromAddress: {
            fullName: '',
            email: '',
            contactNumber: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            gst: ''
        },
        toAddress: {
            fullName: toAddressData?.fullName || '',
            email: toAddressData?.email || '',
            contactNumber: toAddressData?.contactNumber || '',
            address: toAddressData?.address || '',
            city: toAddressData?.city || '',
            state: toAddressData.state || '',
            country: "India",
            zipCode: toAddressData?.zipCode || '',
            gst: toAddressData?.gst || ''
        },
        items: [{ product: '', plan: '', quantity: '', rate: '', taxRate: '', taxAmount: '', discount: '', discountAmount: '', amount: 0 }],
        date: new Date(),
        totalSubTotal: 0,
        totalDiscount: 0,
        totalTax: 0,
        netPayable: 0,
        status: '',
        createdBy: '',
        // expireDate: ''
    });
    // console.log("order:", order)
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const filterData = debounce((query) => {
        if (!query) {
            setFilteredData([]); 
            return;
        }

        const lowerQuery = query.toLowerCase();
        const results = kycUserdata.filter(item =>
            item.fullName.toLowerCase().includes(lowerQuery) ||
            item.email.toLowerCase().includes(lowerQuery) ||
            item.address.toLowerCase().includes(lowerQuery)
        );

        setFilteredData(results.slice(0, 10)); 
    },); 

    
    useEffect(() => {
        filterData(searchQuery);
    }, [searchQuery]);


    useEffect(() => {
        checkDiscount()
        if (decodedToken) {
            const { email = "" } = decodedToken;
            setOrder({ ...order, createdBy: email });
            setUserId(email)
        }
    }, [order.items])


    useEffect(() => {
        if (data) {
            setEditData(false)
            setToAddressData(true)
            setOrder((prev) => ({
                ...prev,
                fromAddress: {
                    fullName: data.fromAddress?.fullName || '',
                    email: data.fromAddress?.email || '',
                    contactNumber: data.fromAddress?.contactNumber || '',
                    address: data.fromAddress?.address || '',
                    city: data.fromAddress?.city || '',
                    state: data.fromAddress?.state || '',
                    country: data.fromAddress?.country || '',
                    zipCode: data.fromAddress?.zipCode || '',
                },
                toAddress: {
                    fullName: data.toAddress?.fullName || '',
                    email: data.toAddress?.email || '',
                    contactNumber: data.toAddress?.contactNumber || '',
                    address: data.toAddress?.address || '',
                    city: data?.toAddress?.city || '',
                    state: data.toAddress?.state || '',
                    country: data.toAddress?.country || '',
                    zipCode: data.toAddress?.zipCode || '',
                },
                ...data
            }));
        }
    }, [data]);

    const checkDiscount = () => {
        order.items.forEach((item) => {
            if (item.discount >= 60) {
                setApproval(true)
            } else {
                setApproval(false)
            }
        });
    };

    const [rows, setRows] = useState([])
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)

    const validationSchema = Yup.object().shape({
        fromAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(
                    /^\+?(\d{1,3})?[-\s]?\d{4}[-\s]?\d{4}[-\s]?\d{2}$/,
                    'Contact Number must be a valid number'
                )
                .required('Contact Number is required'),

            address: Yup.string().trim().required('Address is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
        }),
        toAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(
                    /^\+?(\d{1,3})?[-\s]?\d{4}[-\s]?\d{4}[-\s]?\d{2}$/,
                    'Contact Number must be a valid number'
                )
                .required('Contact Number is required'),
            address: Yup.string().trim().required('Address is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
        })
    });

    const getPlansList = async () => {
        try {
            let params = { skip: 0, limit: 10000 };
            const data = await axiosGetCall("getPlanList", params);
            const { planList: { result }, products } = data;

            let newResult = result.filter(plan => plan.plan_name !== "Free");
            let groupedByProduct = {};

            newResult.forEach(plan => {
                const product = products.find(prod => prod.product_id === plan.product_id);
                if (product) {
                    if (!groupedByProduct[product.product_name]) {
                        groupedByProduct[product.product_name] = [];
                    }
                    groupedByProduct[product.product_name].push({
                        plan_name: plan.plan_name,
                        rate: plan.price
                    });
                }
            });
            const kycUsers = await axiosGetCall("fetchKycUserList", params)
            setKycUserDta(kycUsers?.result)
            setRows(groupedByProduct);
        } catch (error) {
            setNetworkError(true);
            setRows([]);
        }
    };

    useEffect(() => {
        if (load) {
            getPlansList();
        }
        setLoad(false)
    }, [load])

    const inputSx = {
        color: theme.palette.text.primary,
        borderRadius: "6px",
    };

    const handleInputChange = (index, e) => {
        const newItems = [...order.items];
        const { name, value } = e.target;

        if (name === "product") {
            newItems[index].product = value;
            newItems[index].plan = '';
            newItems[index].rate = '';
        } else if (name === "plan") {
            const selectedProductPlans = rows[newItems[index].product];
            const selectedPlan = selectedProductPlans.find(plan => plan.plan_name === value);
            newItems[index].plan = value;
            newItems[index].rate = selectedPlan ? selectedPlan.rate : '';
        } else {
            newItems[index][name] = value;
        }


        if (name === 'rate' || name === 'quantity' || name === 'taxRate' || name === 'discount') {
            const rate = parseFloat(newItems[index].rate) || 0;
            const quantity = parseFloat(newItems[index].quantity) || 0;
            const taxRate = parseFloat(newItems[index].taxRate) || 0;
            const discountRate = parseFloat(newItems[index].discount) || 0;

            const amount = rate * quantity;
            const taxAmount = (amount * taxRate) / 100;
            const discountAmount = (amount * discountRate) / 100;

            newItems[index].amount = amount - discountAmount + taxAmount;
            newItems[index].taxAmount = taxAmount;
            newItems[index].discountAmount = discountAmount;
        }

        setOrder({ ...order, items: newItems });
    };

    const handleRemoveItem = (index) => {
        const newItems = order.items.filter((_, i) => i !== index);
        setOrder({ ...order, items: newItems });
    };

    const calculateTotal = () => {
        const totals = order.items.reduce(
            (acc, item) => {
                const itemSubtotal = item.quantity * item.rate;

                const itemDiscount = itemSubtotal * (item.discount / 100);

                const validDiscount = Math.min(itemDiscount, itemSubtotal);

                const taxableAmount = itemSubtotal;

                const itemTax = taxableAmount * (item.taxRate / 100);

                acc.subtotal += parseFloat(itemSubtotal.toFixed(2));
                acc.discountAmount += parseFloat(validDiscount.toFixed(2));
                acc.taxAmount += parseFloat(itemTax.toFixed(2));

                return acc;
            },
            { subtotal: 0, discountAmount: 0, taxAmount: 0 }
        );
        const netPayable = parseFloat((totals.subtotal - totals.discountAmount + totals.taxAmount).toFixed(2));
        setOrder((prevOrder) => ({
            ...prevOrder,
            totalSubTotal: totals.subtotal,
            totalDiscount: totals.discountAmount,
            totalTax: totals.taxAmount,
            netPayable,
        }));
    };

    useEffect(() => {
        calculateTotal();
    }, [order.items]);

    const sendInvoice = async (order) => {
        const requiredFields = [
            { key: "fromAddress.fullName", value: order.fromAddress.fullName },
            { key: "fromAddress.email", value: order.fromAddress.email },
            { key: "fromAddress.contactNumber", value: order.fromAddress.contactNumber },
            { key: "fromAddress.address", value: order.fromAddress.address },
            { key: "fromAddress.state", value: order.fromAddress.state },
            { key: "fromAddress.zipCode", value: order.fromAddress.zipCode },
            { key: "toAddress.fullName", value: order.toAddress.fullName },
            { key: "toAddress.email", value: order.toAddress.email },
            { key: "toAddress.contactNumber", value: order.toAddress.contactNumber },
            { key: "toAddress.address", value: order.toAddress.address },
            { key: "toAddress.state", value: order.toAddress.state },
            { key: "toAddress.zipCode", value: order.toAddress.zipCode },
            // { key: "expireDate", value: order.expireDate }
        ];

        const missingFields = requiredFields.filter((field) => !field.value);

        if (missingFields.length > 0) {
            console.warn("Missing Fields:", missingFields.map(f => f.key));
            return renderErrorMessage("All * fields are mandatory!");
        }

        const invalidItems = order.items.some((i, index) => {
            const isInvalid = i.quantity === "" || i.rate === "";
            if (isInvalid) {
                console.warn(`Item ${index} has invalid quantity or rate:`, i);
            }
            return isInvalid;
        });

        if (invalidItems) {
            return renderErrorMessage("All * fields are mandatory!");
        }

        try {
            setLoading(true);
            const { _id } = data || {};
            if (_id) {
                Object.assign(order, { action: "Edit", flag: 2, _id });
            }
            order.user = userId;

            if (approval) {
                order.getApproveStatus = "Need approval";
            } else {
                order.getApproveStatus = "Approved";
            }

            if (order.items.some(({ quantity, rate }) => !quantity || !rate)) {
                setLoading(false);
                return renderErrorMessage("All * fields are mandatory!");
            }

            const result = await axiosPostCall("sendInvoice", { formData: order });
            if (result.message === "Invoice sent successfully") {
                const successMessage = _id
                    ? "Quotation updated successfully!"
                    : "Quotation created successfully!";
                renderSuccessMessage(successMessage);
                navigate("/billing");
            }
        } catch (error) {
            console.error(error);
            renderErrorMessage("An error occurred while sending the invoice.");
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const handlePreview = () => {
        const requiredFields = [
            { key: "fromAddress.fullName", value: order.fromAddress.fullName },
            { key: "fromAddress.email", value: order.fromAddress.email },
            { key: "fromAddress.contactNumber", value: order.fromAddress.contactNumber },
            { key: "fromAddress.address", value: order.fromAddress.address },
            { key: "fromAddress.state", value: order.fromAddress.state },
            { key: "fromAddress.zipCode", value: order.fromAddress.zipCode },
            { key: "toAddress.fullName", value: order.toAddress.fullName },
            { key: "toAddress.email", value: order.toAddress.email },
            { key: "toAddress.contactNumber", value: order.toAddress.contactNumber },
            { key: "toAddress.address", value: order.toAddress.address },
            { key: "toAddress.state", value: order.toAddress.state },
            { key: "toAddress.zipCode", value: order.toAddress.zipCode },
            // { key: "expireDate", value: order.expireDate }
        ];

        const missingFields = requiredFields.filter((field) => !field.value);

        if (missingFields.length > 0) {
            console.warn("Missing Fields:", missingFields.map(f => f.key));
            return renderErrorMessage("All * fields are mandatory!");
        }

        const invalidItems = order.items.some((i, index) => {
            const isInvalid = i.quantity === "" || i.rate === "";
            if (isInvalid) {
                console.warn(`Item ${index} has invalid quantity or rate:`, i);
            }
            return isInvalid;
        });

        if (invalidItems) {
            return renderErrorMessage("All * fields are mandatory!");
        }
        confirm.onTrue();
    };

    const handleNameSelect = (event, selectedValue) => {
        if (selectedValue) {
            setToAddressData(true);
            setOrder((prev) => ({
                ...prev,
                toAddress: {
                    fullName: selectedValue.fullName,
                    email: selectedValue.email,
                    contactNumber: selectedValue.contactNumber,
                    address: selectedValue.address,
                    city: selectedValue?.city,
                    state: selectedValue.state,
                    country: selectedValue?.country,
                    zipCode: selectedValue.pincode,
                    gst: selectedValue.gst,
                }
            }));
        }
    };

    useEffect(() => {
        if (socket) {
            socket.emit("getInvoiceAdditionalInfo", {}, (err, data) => {
                if (err) return renderErrorMessage(err.message);
                if (!data) return renderErrorMessage("No invoice additional info received.");

                const details = data?.companydetails?.result || [];
                setCompanyDetails(details);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (companyDetails.length > 0) {
            setOrder(prev => ({
                ...prev,
                fromAddress: {
                    fullName: companyDetails[0]?.name || '',
                    email: companyDetails[0]?.email || '',
                    contactNumber: companyDetails[0]?.phone || '',
                    address: companyDetails[0]?.address || '',
                    city: companyDetails[0]?.city || '',
                    state: companyDetails[0]?.state || '',
                    country: companyDetails[0]?.country || "India",
                    zipCode: companyDetails[0]?.zipcode || '',
                    gst: companyDetails[0]?.gst || ''
                }
            }));
        }
    }, [companyDetails]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '25px',
                    width: '60%',
                    margin: 'auto',
                    padding: '25px',
                    boxShadow: 3,
                    borderRadius: '8px',
                    backgroundColor: "#fff",
                    paddingBottom: "3px"

                }}
                noValidate
                autoComplete="off"
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        fromAddress: {
                            fullName: data?.fromAddress?.fullName || companyDetails[0]?.name, email: data?.fromAddress?.email || companyDetails[0]?.email, contactNumber: data?.fromAddress?.contactNumber || companyDetails[0]?.phone, address: data?.fromAddress?.address || companyDetails[0]?.address,
                            city: data?.fromAddress?.city || companyDetails[0]?.city, zipCode: data?.fromAddress?.zipCode || companyDetails[0]?.zipcode, state: data?.fromAddress?.state || companyDetails[0]?.state, country: data?.fromAddress?.country || companyDetails[0]?.country
                        },
                        toAddress: {
                            fullName: data?.toAddress?.fullName || order?.toAddress?.fullName, email: data?.toAddress?.email || order?.toAddress.email, contactNumber: data?.toAddress?.contactNumber || order.toAddress?.contactNumber, address: data?.toAddress?.address || order?.toAddress?.address,
                            city: data?.toAddress?.city || order.toAddress.city, zipCode: data?.toAddress?.zipCode || order?.toAddress?.zipCode, state: data?.toAddress?.state || order?.toAddress?.state, country: data?.toAddress?.country || order?.toAddress?.country, gst: data?.toAddress?.gst || order?.toAddress?.gst
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setOrder((prev) => ({
                            ...prev,
                            fromAddress: values.fromAddress,
                            toAddress: values.toAddress,
                        }));
                        sendInvoice(order);
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form style={{ width: '100%', gap: 1 }}>
                            <Box
                                sx={{ width: '100%', gap: 1, }}>
                                <h4 style={{ marginTop: '20px ' }}>From</h4>
                                <RowTow sx={{ display: 'flex', width: '100%' }}>
                                    {<Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="fromAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.fullName && errors.fromAddress?.fullName}
                                        error={touched.fromAddress?.fullName && Boolean(errors.fromAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.fullName', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, fullName: value },
                                            }));
                                        }}
                                        value={values.fromAddress.fullName || ""}
                                    />}

                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="fromAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.email && errors.fromAddress?.email}
                                        error={touched.fromAddress?.email && Boolean(errors.fromAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.email', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, email: value },
                                            }));
                                        }}
                                        value={values.fromAddress.email || ""}
                                    />

                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Contact Number *"
                                        name="fromAddress.contactNumber"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.contactNumber && errors.fromAddress?.contactNumber}
                                        error={touched.fromAddress?.contactNumber && Boolean(errors.fromAddress?.contactNumber)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.contactNumber', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, contactNumber: value },
                                            }));
                                        }}
                                        value={values.fromAddress.contactNumber || ""}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Address *"
                                        name="fromAddress.address"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.address && errors.fromAddress?.address}
                                        error={touched.fromAddress?.address && Boolean(errors.fromAddress?.address)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.address', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, address: value },
                                            }));
                                        }}
                                        value={values.fromAddress.address || ""}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="City *"
                                        name="fromAddress.city"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.city', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, city: value },
                                            }));
                                        }}
                                        value={values.fromAddress.city || ""}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Zip/Code *"
                                        name="fromAddress.zipCode"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.zipCode && errors.fromAddress?.zipCode}
                                        error={touched.fromAddress?.zipCode && Boolean(errors.fromAddress?.zipCode)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.zipCode', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, zipCode: value },
                                            }));
                                        }}
                                        value={values.fromAddress.zipCode || ""}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="State *"
                                        name="fromAddress.state"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.state && errors.fromAddress?.state}
                                        error={touched.fromAddress?.state && Boolean(errors.fromAddress?.state)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.state', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, state: value },
                                            }));
                                        }}
                                        value={values.fromAddress.state || ""}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Country *"
                                        name="fromAddress.country"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.country', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, country: value },
                                            }));
                                        }}
                                        value={values.fromAddress.country || ""}
                                    />
                                </RowTow>
                            </Box>

                            <Box sx={{ width: '100%', gap: 1, padding: '0 5px' }}>
                                <h4 style={{ marginTop: '20px' }}>To</h4>
                                {editData ? <Autocomplete
                                // options={kycUserdata}
                                    // options={dummyData}
                                    // getOptionLabel={(option) => option.fullName}
                                    // renderInput={(params) => <TextField {...params} label="Search..." />}
                                    // renderOption={(props, option) => (
                                    //     <li {...props}>
                                    //         {option.fullName} - {option.email} - {option.address}
                                    //     </li>
                                    // )}
                                    // onChange={handleNameSelect}
                                    // sx={{ width: '100%', marginBottom: 2 }}
                                    options={filteredData}
                                    getOptionLabel={(option) => option.fullName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search..."
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.fullName} - {option.email} - {option.address}
                                        </li>
                                    )}
                                    onChange={handleNameSelect}
                                    sx={{ width: "100%", marginBottom: 2 }}
                                /> : null}
                                {toAddressData ? <> <RowTow sx={{ display: 'flex', width: '100%' }}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="toAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.fullName && errors.toAddress?.fullName}
                                        error={touched.toAddress?.fullName && Boolean(errors.toAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.fullName', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, fullName: value },
                                            }));
                                        }}
                                        value={values.toAddress.fullName}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="toAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.email && errors.toAddress?.email}
                                        error={touched.toAddress?.email && Boolean(errors.toAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.email', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, email: value },
                                            }));
                                        }}
                                        value={values.toAddress.email}
                                    />

                                </RowTow>
                                    <RowTow>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="Contact Number *"
                                            name="toAddress.contactNumber"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            helperText={touched.toAddress?.contactNumber && errors.toAddress?.contactNumber}
                                            error={touched.toAddress?.contactNumber && Boolean(errors.toAddress?.contactNumber)}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.contactNumber', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, contactNumber: value },
                                                }));
                                            }}
                                            value={values.toAddress.contactNumber}
                                        />


                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="Address *"
                                            name="toAddress.address"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            helperText={touched.toAddress?.address && errors.toAddress?.address}
                                            error={touched.toAddress?.address && Boolean(errors.toAddress?.address)}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.address', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, address: value },
                                                }));
                                            }}
                                            value={values.toAddress.address}
                                        />
                                    </RowTow>
                                    <RowTow>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="City "
                                            name="toAddress.city"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.city', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, city: value },
                                                }));
                                            }}
                                            value={values.toAddress.city}
                                        />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="Zip/Code *"
                                            name="toAddress.zipCode"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            helperText={touched.toAddress?.zipCode && errors.toAddress?.zipCode}
                                            error={touched.toAddress?.zipCode && Boolean(errors.toAddress?.zipCode)}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.zipCode', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, zipCode: value },
                                                }));
                                            }}
                                            value={values.toAddress.zipCode}
                                        />
                                    </RowTow>
                                    <RowTow>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="State *"
                                            name="toAddress.state"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            helperText={touched.toAddress?.state && errors.toAddress?.state}
                                            error={touched.toAddress?.state && Boolean(errors.toAddress?.state)}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.state', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, state: value },
                                                }));
                                            }}
                                            value={values.toAddress.state}
                                        />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label="GST "
                                            name="toAddress.gst"
                                            variant="outlined"
                                            InputProps={{ sx: inputSx }}
                                            sx={{ marginBottom: 1 }}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setFieldValue('toAddress.gst', value);
                                                setOrder((prev) => ({
                                                    ...prev,
                                                    toAddress: { ...prev.toAddress, gst: value },
                                                }));
                                            }}
                                            value={values.toAddress.gst}
                                        />
                                    </RowTow> </> : null}
                            </Box>

                            <div>
                                <h4>Services</h4>

                                {order.items.map((item, index) => {
                                    const selectableValues = Array.from({ length: 1 }, (_, i) => i + 1);
                                    const selectableTax = Array.from({ length: 1 }, (_, i) => i + 18);
                                    return (
                                        <div key={index} style={{ marginBottom: '15px', position: 'relative' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel>Product *</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Product *"
                                                            variant="outlined"
                                                            name="product"
                                                            value={item.product}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                        >
                                                            {Object.keys(rows).map((productName, prodIndex) => (
                                                                <MenuItem key={prodIndex} value={productName}>
                                                                    {productName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel>Plan *</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Plan *"
                                                            variant="outlined"
                                                            name="plan"
                                                            value={item.plan}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            disabled={!item.product}
                                                        >
                                                            {item.product && rows[item.product]?.map((plan, planIndex) => (
                                                                <MenuItem key={planIndex} value={plan.plan_name}>
                                                                    {plan.plan_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Price *"
                                                        variant="outlined"
                                                        type="number"
                                                        name="rate"
                                                        value={item.rate}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Autocomplete
                                                        freeSolo
                                                        clearOnEscape
                                                        options={selectableValues.map((value) => value.toString())}
                                                        value={item.quantity.toString()}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleInputChange(index, { target: { name: "quantity", value: newInputValue } });
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            handleInputChange(index, { target: { name: "quantity", value: newValue || "" } });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Quantity *"
                                                                variant="outlined"
                                                                type="text"
                                                                onFocus={() => setIsFocused(true)}
                                                                onBlur={() => setIsFocused(false)}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: isFocused ? params.InputProps.endAdornment : null,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid container spacing={2} sx={{ mt: '1px' }}>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Autocomplete
                                                        freeSolo
                                                        clearOnEscape
                                                        options={selectableTax.map((value) => value.toString())}
                                                        value={item.taxRate.toString()}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleInputChange(index, { target: { name: "taxRate", value: newInputValue } });
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            handleInputChange(index, { target: { name: "taxRate", value: newValue || "" } });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label="Tax(%)"
                                                                variant="outlined"
                                                                type="text"
                                                                onFocus={() => setIsFocused(true)}
                                                                onBlur={() => setIsFocused(false)}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: isFocused ? params.InputProps.endAdornment : null,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                {/*  <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Disc (%)"
                                                        variant="outlined"
                                                        InputProps={{ sx: inputSx }}
                                                        inputProps={{ min: 1, max: 99 }}
                                                        type="number"
                                                        name="discount"
                                                        value={item.discount}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === "" || (Number(value) >= 1 && Number(value) <= 99)) {
                                                                handleInputChange(index, { target: { name: "discount", value } });
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = Math.max(1, Math.min(99, Number(e.target.value) || 0));
                                                            handleInputChange(index, { target: { name: "discount", value } });
                                                        }}
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Disc (%)"
                                                        variant="outlined"
                                                        InputProps={{ sx: inputSx }}
                                                        inputProps={{ min: 1, max: 99 }}
                                                        type="number"
                                                        name="discount"
                                                        value={item.discount === null || item.discount === undefined ? "" : item.discount} // Show empty if cleared
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow empty input (for backspacing) or enforce range 1-99
                                                            if (value === "" || (Number(value) >= 1 && Number(value) <= 99)) {
                                                                handleInputChange(index, { target: { name: "discount", value: value === "" ? "" : Number(value) } });
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value = e.target.value.trim();
                                                            // If empty, keep it empty; else, clamp between 1 and 99
                                                            const finalValue = value === "" ? "" : Math.max(1, Math.min(99, Number(value)));
                                                            handleInputChange(index, { target: { name: "discount", value: finalValue } });
                                                        }}
                                                    />
                                                </Grid>


                                                <Grid item xs={12} sm={6} md={3}>
                                                    <TextField
                                                        key={index}
                                                        fullWidth
                                                        label="Amount(₹)"
                                                        variant="outlined"
                                                        InputProps={{
                                                            sx: inputSx

                                                        }}
                                                        type="number"
                                                        name="amount"
                                                        value={order.items[index]?.amount?.toFixed(2) || 0}
                                                        onChange={(e) => {
                                                            const updatedItems = [...order.items];
                                                            const newAmount = parseFloat(e.target.value) || 0;
                                                            updatedItems[index] = {
                                                                ...updatedItems[index],
                                                                amount: newAmount,
                                                            };
                                                            setOrder({ ...order, items: updatedItems });
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Tooltip title="Delete Item">
                                                        <IconButton
                                                            onClick={() => handleRemoveItem(index)}
                                                            sx={{ ml: 0.5, mt: '9px' }}
                                                        >
                                                            <Iconify icon="solar:trash-bin-trash-bold" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Add Item">
                                                        <IconButton
                                                            onClick={() =>
                                                                setOrder({
                                                                    ...order,
                                                                    items: [...order.items, { product: '', plan: '', quantity: '', rate: '', taxRate: '', taxAmount: 0, discount: '', discountAmount: 0, amount: 0 }]
                                                                })
                                                            }
                                                            sx={{
                                                                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
                                                                border: (theme) => `dashed 1px ${theme.palette.divider}`,
                                                                ml: 0.5, mt: '9px', height: "25px",width:"25px"
                                                            }}
                                                        >
                                                            <AddRoundedIcon  />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })}
                                {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                    <h5 style={{ margin: 0 }}>Expire on * :</h5>
                                   
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <RowBox>

                                            <RowInnerBox>

                                                <DatePicker
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    onChange={(e) => setOrder((prev) => ({
                                                        ...prev,
                                                        expireDate: +new Date(e)
                                                    }))} />

                                            </RowInnerBox>

                                        </RowBox>
                                    </LocalizationProvider>
                                </Box> */}
                            </div>
                            <div style={{ width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    {[
                                        { label: 'Subtotal(₹):', value: order.totalSubTotal.toFixed(2), isBold: true },
                                        { label: 'Discount(₹):', value: order.totalDiscount.toFixed(2) },
                                        { label: 'Tax(₹):', value: order.totalTax.toFixed(2) }
                                    ].map((item, index) => {
                                        const formattedValue = item.label === 'Discount(₹):' ? `- ${formatCurrency(Math.abs(item.value))}` : formatCurrency(item.value);

                                        return (
                                            <div key={index} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: '5px' }}>
                                                <div style={{ textAlign: 'right', width: '80%' }}>
                                                    <span style={{ fontWeight: item.isBold ? 'bold' : 'normal' }}>{item.label}</span>
                                                </div>
                                                <div style={{ textAlign: 'right', width: '20%' }}>
                                                    <span>{formattedValue}</span>
                                                </div>
                                                <Divider sx={{ my: 0.5, borderBottomWidth: 1, flex: 1 }} />
                                            </div>
                                        );
                                    })}

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Divider sx={{ my: 0.5, borderBottomWidth: 1, width: '28%', marginLeft: 'auto' }} />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <div style={{ textAlign: 'right', width: '80%' }}>
                                            <span style={{ fontWeight: 'bold' }}>Total:</span>
                                        </div>
                                        <div style={{ textAlign: 'right', width: '20%' }}>
                                            <span>₹ {formatCurrency(order.netPayable.toFixed(2))}</span>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pt: '10px',
                                pb: '20px',
                                gap: "15px"
                            }}>
                                <LoadingButton
                                    variant='outlined'
                                    onClick={() => handlePreview()}
                                    color='primary'
                                >
                                    Preview
                                </LoadingButton>
                                {approval ? <LoadingButton
                                    type='submit'
                                    variant='outlined'
                                    color='warning'
                                >
                                    {loading ? <><CircularProgress size={17} sx={{ color: "#1976d2", marginRight: '8px' }} /> Sending..</> : 'Get Approval'}
                                </LoadingButton> :
                                    <LoadingButton
                                        type='submit'
                                        variant='outlined'
                                    >
                                        {loading ? <><CircularProgress size={17} sx={{ color: "#1976d2", marginRight: '8px' }} /> Sending..</> : 'Send'}
                                    </LoadingButton>}
                            </Box>
                        </Form>
                    )}
                </Formik>

            </Box>

            {/* <ToastContainer /> */}

            <PreviewComponent
                open={confirm.value}
                setOpen={confirm.onFalse}
                order={order}
            />
        </>
    );
};

export default BillingForm;
